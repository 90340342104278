import { Col, Container, Row } from "react-bootstrap";
import "./About.css";
import "../assets/css/style.css";
import "animate.css";
import aboutbg from "../../../pages/Masala-of-india/assets/images/about/vegetales-shapes.png";
import aboutimg from "../../../pages/Masala-of-india/assets/images/about/Image.png";
const CommonHomeAbout = () => {
  return (
    <>
      <section className="about-section about-phn-view" id="about">
        <div className="container-md container-fluid px-0 px-3">
          <div className="row">
            <div className="col-lg-6">
              <div className="content">
                <div className="section-header">
                  <h2 className="wow fadeInUp">Our Story</h2>
                  <h3 className="">
                    Take a culinary tour of India's royal flavours
                  </h3>
                  <p className="masala-common-content">
                    Using freshly chosen secret ingredients passed down through
                    generations,<br /> Mr. Sanjay Sharma, the owner of Masala Of India
                    has set a new taste standard for Indian food. His fine
                    skills to cater food lovers with the most palatable
                    healthiest Indian food and his respect for more than
                    100-year-old Indian culinary tradition led him to become one
                    of the featured food vendors at the recent Northgate
                    Festival. The passion to provide tempting food along with
                    the concern for the well-being of the consumers gave birth
                    to the healthiest Indian food cuisine one can find in the
                    whole country.
                  </p>
                  <p className="masala-common-content">
                    Since 2000, Masala of India has been catering Indian
                    indulgences to Indian food lovers giving your taste buds a
                    treat like never before. The sizzling spicy flavours of our
                    appetizers directly from the spice capital of the world will
                    leave you licking your fingers and lips.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="img">
                <img className="bg-shape" src={aboutbg} alt="masala of india" />
                <img src={aboutimg} alt="masla of india" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommonHomeAbout;
