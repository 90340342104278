import Footer from "../Footer/Footer";
import CommonHomeNavbar from "../Navbar/CommonHomeNavbar";
import { DatePicker, Space } from "antd";
// import "./ResrvationPage.css";
import "../assets/css/style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import Notiflix from "notiflix";
import PostApiCall from "../../../helper/PostAPI";
import "../Catering/Catering.css";
import { Helmet } from "react-helmet";

export default function Contact() {
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      comments: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required*"),
      phone: Yup.string()
        .matches(/^\d+$/, "Phone number is invalid")
        .required("This field is required*"),
      email: Yup.string()
        .email("Email is Invalid")
        .required("This field is required*"),
    }),
    onSubmit: (values) => {
      const { name, phone, email, comments } = values;
      sendMail(name, phone, email, comments);
      formik.resetForm();
    },
  });

  // send mail to the admin
  function sendMail(name, phone, email, comments) {
    Notiflix.Loading.Dots("Please Wait...");
    PostApiCall.postRequest(
      {
        id: null,
        type: "Enquiry",
        contactperson: name,
        contactnumber: phone,
        email: email,
        message: comments,
      },
      "contact"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Mail sent");
        } else {
          Notiflix.Notify.Failure("Failed");
        }
      })
    );
  }

  return (
    <>
      <CommonHomeNavbar />

      <Helmet>
        {/* meta tags */}
        <title>Contact Us - Masala of India: Reach out for inquiries</title>
        <meta
          name="description"
          content="Get in touch with Us through our website's contact page. Find our contact details and reach out to us for any information or assistance."
        />
        <meta
          name="keywords"
          content="Contact Us, Masala Of India, best indian restaurant in  seattle, indian cuisine in seattle, indian food lunch buffet in seattle, indian restaurants near me delivery, online indian food delivery near me, indian restaurants in seattle washington, best indian restaurants in seattle, indian buffet bellevue,indian food buffets, best indian restaurants in bellevu, indian restaurants in seattle"
        />

        {/* OG tags */}
        <meta
          property="og:title"
          content="Contact Us - Masala of India: Reach out for inquiries"
        />
        <meta
          property="og:description"
          content="Get in touch with Us through our website's contact page. Find our contact details and reach out to us for any information or assistance."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content=" https://www.masalaofindia.com/contact"
        />
        <meta
          property="og:image"
          content=" https://www.masalaofindia.com/static/media/masala-of-india.c5a7d2004e43f4db1721.png"
        />
        <meta property="og:site_name" content="masala of india" />

        {/* Twitter tags */}
        <meta
          name="twitter:title"
          content="Contact Us - Masala of India: Reach out for inquiries"
        />
        <meta
          name="twitter:description"
          content="Get in touch with Us through our website's contact page. Find our contact details and reach out to us for any information or assistance."
        />
        <meta
          name="twitter:image"
          content=" https://www.masalaofindia.com/static/media/masala-of-india.c5a7d2004e43f4db1721.png"
        />
        <meta name="twitter:site" content="masala of india" />
        <link rel="canonical" href="https://www.masalaofindia.com/contact"></link> 
      </Helmet>

      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-header-box">
                <h1 class="text-anime">Contact Us</h1>
                <nav>
                  <ol class="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Contact</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="book-table-section book-reservation-section my-4 my-lg-5">
        <div className="container-md container-fluid px-0 px-3">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="content">
                <div className="section-header mb-1">
                  {/* <h2>Serve happiness to your guests</h2> */}
                  <h2>Get in touch with Masala Of India</h2>
                  <h3>For unforgettable dining journey</h3>
                  <p>
                    At Masala Of India, we believe that dining should be an
                    unforgettable journey. If you have any questions or special
                    requests, please don’t hesitate to contact us. Our team is
                    dedicated to making your dining experience truly
                    exceptional, from the moment you step through our doors
                    until the moment you leave with a smile on your face. Please
                    feel free to use the contact form below, call us during
                    business hours, or email us directly. We look forward to
                    hearing from you soon!
                  </p>
                </div>
              </div>

              {/*  ***** Reservation form **** */}
              <div className="book-table-box mt-4 col-lg-10 mx-auto">
                <div className="book-form">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-12 col-md-4 mb-4">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Full Name *"
                          aria-label=".form-control-lg example"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-12 col-md-4 mb-4">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Phone Number *"
                          aria-label=".form-control-lg example"
                          name="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.phone}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-12 col-md-4 mb-4">
                        <input
                          className="form-control form-control-lg"
                          type="email"
                          placeholder="Email Address *"
                          aria-label=".form-control-lg example"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-12 mb-4">
                        <textarea
                          className="form-control form-control-lg catering-textarea"
                          id="validationTextarea"
                          placeholder="Message"
                          name="comments"
                          value={formik.values.comments}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        ></textarea>
                      </div>

                      <div className="col-lg-12 text-center">
                        <button type="submit" className="custom-button">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
