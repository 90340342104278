import "animate.css";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import PostApiCall from "../../../helper/PostAPI";
import "../assets/css/style.css";
import "./ImageGallery.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { store } from "../../context/store";
import ImageGalleryPage from "../ImageGalleryPage/ImageGalleryPage";

const CommonHomeImageGallery = () => {
  var settings = {
    dots: true,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
  };
  const [gallaryImages, setGallaryImages] = useState([]);
  const { clientDetails } = useContext(store);
  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_restaurant_id = 225736`,
        columns: "*",
      },
      "getRestaurantImages"
    ).then((results) =>
      results?.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setGallaryImages(obj.message);
          Notiflix.Loading.Remove();
        }
      })
    )
  }, []);

  return (
    <>
      <section className="mt-md-5 mt-1">
        <Container fluid className="h-100">
          <Row className="align-items-center justify-content-center h-100">
            {/* <Col lg={12}> */}
            <Slider {...settings} className="imagegallery-section">
              {gallaryImages?.map((data, i) => {
                return (
                  <div className="text-center px-2 col-4">
                    <div className="testimonial-slid">
                      <a>
                        <img
                          src={data.fld_image_url}
                          alt={clientDetails[0]?.fld_restaurantname}
                          className="img-fluid gallery-img-border-radius"
                        />
                      </a>
                    </div>
                  </div>
                );
              })}
            </Slider>
            {/* </Col> */}
            <Col lg={12} className="text-center mt-4">
              
                <a
                  class="review-btn mb-0 text-white"
                  href="/gallery">
                  View All
                </a>
              
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default CommonHomeImageGallery;
