import moment from "moment";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FssaiImg from "../../assets/img/FSSAI_logo.png";
import GrubDigestLogo from "../../assets/img/logo-grubdigest.png";
import { store } from "../../pages/context/store";

import GPayIcon from "../../assets/img/payment/gpay.svg";
import BHIMUPIIcons from "../../assets/img/payment/bhim.svg";
import UPI from "../../assets/img/payment/upi.svg";
import PhonePayIcon from "../../assets/img/payment/phonepe.svg";
import VisaIcon from "../../assets/img/payment/visa.svg";
import MastercardIcon from "../../assets/img/payment/mastercard.svg";
import AmexIcon from "../../assets/img/payment/amex.svg";
import RupayIcon from "../../assets/img/payment/rupay.svg";
import PaytmIcon from "../../assets/img/payment/paytm.svg";
import AirtelpayIcon from "../../assets/img/payment/airtel.svg";
import FreechargeIcon from "../../assets/img/payment/freecharge.svg";
import grubLogo from "../../assets/img/logo-grubdigest.png";
import "./Footer.css";

function Footer() {
  const { fssai, setFssai, restaurantName, cardAccepted, setcardAccepted } =
    useContext(store);
  return (
    <>
      {/* <footer className="border-lg-top pt-lg-2 pb-lg-4 d-lg-block d-md-block d-sm-none d-none"> */}
      <footer className="top-footer-links py-2 d-lg-block d-md-block">
        <Container fluid>
          <Row className="text-lg-center">
            <Col lg={6}>
              <ul className="footer-nav">
                <li>
                  <a href="/terms-condition">Terms and Conditions</a>
                </li>
                <li>
                  <a href="/privacy">Privacy Policy</a>
                </li>
                {/* <li>
                  <a href="">FAQ</a>
                </li> */}
                <li>
                  <a href="/disclaimer">Disclaimer</a>
                </li>
              </ul>
            </Col>
            {/* <Col lg={4} className="px-lg-3 px-md-3 px-0">
              <p className="mb-0 text-lg-left text-left mt-lg-0 mt-md-0 mt-4">
                We Accept
              </p>
              <ul className="payment-icons list--inline site-footer__icon-list">
                //  {cardAccepted != null ? (
                //   cardAccepted.map((data, i) => {
                //     return (
                //       <li
                //         className="payment-icon"
                //         dangerouslySetInnerHTML={{ __html: data.FLD_Card_Icon }}
                //       ></li>
                //     );
                //   })
                // ) : (
                //   <div></div>
                // )} 

                <li className="payment-icon">
                  <img className="icon icon--full-color" src={VisaIcon} />
                </li>

                <li className="payment-icon">
                  <img
                    className="icon icon--full-color"
                    src={MastercardIcon}
                  />
                </li>
                <li className="payment-icon">
                  <img
                    className="icon icon--full-color"
                    src={AmexIcon}
                    style={{ backgroundColor: "#ffffff" }}
                  />
                </li>
                <li className="payment-icon">
                  <img
                    className="icon icon--full-color"
                    src={BHIMUPIIcons}></img>
                </li>

                <li className="payment-icon">
                  <img src={UPI} className="icon icon--full-color"></img>
                </li>
                <li className="payment-icon">
                  <img className="icon icon--full-color" src={RupayIcon} />
                </li>
                <li className="payment-icon">
                  <img className="icon icon--full-color" src={PaytmIcon} />
                </li>
                //  <li className="payment-icon">
                //   <img
                //     className="icon icon--full-color"
                //     src={FreechargeIcon}
                //   />
                // </li> 
                <li className="payment-icon">
                  <img
                    className="icon icon--full-color"
                    src={GPayIcon}></img>
                </li>

                <li className="payment-icon">
                  <img
                    src={PhonePayIcon}
                    className="icon icon--full-color"></img>
                </li>
              </ul>
            </Col> */}
            <Col lg={6}>
              <p className="text-lg-end text-center text-white mb-lg-0 mb-0">
                &copy; {moment().format("YYYY")} {restaurantName}. All right
                reserved.
              </p>
              {fssai != null ? (
                <div className="d-lg-flex gap-1 align-items-center justify-content-end mt-lg-0 mt-3 text-lg-end text-md-start text-center">
                  <img src={FssaiImg} className="fssai-logo" alt="fssai" />
                  <p className="mb-0 text-lg-end text-md-start text-center">
                    {fssai}
                  </p>
                </div>
              ) : (
                ""
              )}
            </Col>
            {/* <Col
              lg={12}
              className="d-flex justify-content-center align-items-center order-lg-1 order-2 gap-2"
            ></Col> */}
          </Row>
          {/* <Row> */}
          {/* <Col
              lg={6}
              className="d-flex order-lg-1 order-2 gap-2 align-items-center"
            >
              <p className="text-lg-start text-center mb-lg-0 mb-0 d-lg-block d-none">
                Powered by{" "}
                <a href="http://globaltrendz.com/" className="poweredby">
                  <img
                    src={GrubDigestLogo}
                    className="powered-by"
                    alt="Grub Digest Logo"
                  />
                </a>
                .
              </p>
            </Col> */}
          {/* </Row> */}
        </Container>
      </footer>
      <div className="container-fluid footer-bottom-company-name ">
        <div className="row">
          <div className="col-12 text-white text-center text-lg-start py-3">
            {/* <a className="text-white me-3" href="https://globaltrendz.com/" target="blank"> Design Global Trendz,</a>  */}
            <a href="https://grubdigest.com/" target="blank">
              {" "}
              Powered By <img src={grubLogo} className="img-fluid" />
            </a>
          </div>
          {/* <div className="col-12 col-md-6 text-md-end text-center text-white py-3"><a className="text-white" href="https://globaltrendz.com/" target="blank"> Design Global Trendz</a></div> */}
        </div>
      </div>
    </>
  );
}

export default Footer;
