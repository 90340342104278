import "bootstrap/dist/css/bootstrap.min.css";
import { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Privacy from "../src/pages/Masala-of-india/StaticPages/Privacy";
import "./App.css";
import PageNotFound from "./Error/PageNotFound";
import PostApiCall from "./helper/PostAPI.js";
import { store } from "./pages/context/store.js";
import CustomerDashboard from "./pages/CustomerDashboard/CustomerDashboard";
import Home from "./pages/Home/Home";
import Catering from "./pages/Masala-of-india/CateringPage/Catering.js";
import ChefPage from "./pages/Masala-of-india/ChefPage/ChefPage.js";
import CommonHome from "./pages/Masala-of-india/CommonHome/CommonHome";
import Contact from "./pages/Masala-of-india/ContactUs/Contact.js";
import Cropped from "./pages/Masala-of-india/ImageGalleryPage/ImageGalleryPage.js";
import ResrvationPage from "./pages/Masala-of-india/ResrvationPage/ResrvationPage.js";
import Disclaimer from "./pages/Masala-of-india/StaticPages/Disclaimer";
import TermsCondition from "./pages/Masala-of-india/StaticPages/TermsCondition";
import PaymentSuccess from "./pages/Payment/PaymentSuccess";
import RecipeDetail from "./pages/RecipeDetail/RecipeDetail";

function App() {
  const { setClientDetails, setRestaurantOpen, setFssai,
    setRestaurantAddress, setRestaurantTime, setRestaurantName, setCurrencyCode,
    setCustomerValidation, setClientLogo } = useContext(store)
  useEffect(() => {
    localStorage.removeItem('LoginData');
    PostApiCall.postRequest(
      {
        id: "",
      },
      "getClientData"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setClientDetails(obj1.message);
          setRestaurantOpen(obj1.message[0]?.restaurant_open);
          setFssai(obj1.message[0].fld_fssai_number);
          setRestaurantAddress(
            obj1.message[0].fld_address +
            ", " +
            obj1.message[0].city_name +
            ", " +
            obj1.message[0].state_name
          );
          setRestaurantTime(obj1.message[0].res_timings);
          setRestaurantName(obj1.message[0].fld_restaurantname);
          setCurrencyCode(obj1.message[0].fld_currency);
          setCustomerValidation(obj1.message[0].fld_customer_validation_using);
          setClientLogo(obj1.message[0]?.fld_image);
        }
      })
    );
  }, [])
  return (
    <div className="container-fluid main-route-box p-0 m-0">
      <Routes>
        {/* ******* Redirection Url Paths **************** */}
        <Route
          path="/top-deals-today"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/fish-biryani"
          element={<Navigate to="/order-online" replace />}
        />
        {/* <Route path="/contact" element={<Navigate to="/contact" replace />} /> */}
        <Route
          path="/items/gulab-jamun"
          element={<Navigate to="/order-online" replace />}
        />
        <Route path="/items/curry" element={<Navigate to="/order-online" replace />} />
        <Route
          path="/items/jalfrazie"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/chili-chicken"
          element={<Navigate to="/order-online" replace />}
        />
        <Route path="/items/saag" element={<Navigate to="/order-online" replace />} />
        <Route
          path="/items/non-veg-mixed-appetizer"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/vegetable-samosa"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/orange-juice"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/daal-tardka"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/onion-bhajia"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/andra-veg-curry"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/chicken-soup"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/parantha"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/chicken-tikka-kabab"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/vegetable-manchurian"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/rack-of-lamb"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/menus/drinks-ordering"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/butter"
          element={<Navigate to="/order-online" replace />}
        />
        <Route path="/items/matar" element={<Navigate to="/order-online" replace />} />
        <Route
          path="/items/aloo-gobi"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/pav-bhaji"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/holiday-specials"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/tabbouleh"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/chicken-salad"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/orders/pick-up-for-thursday-11-2-diwali-pre-order"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/orders/pick-up-for-friday-11-3-diwali-pre-order"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/events/pick-up-for-thursday-11-02-diwali-ordering-event"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/events/pick-up-for-saturday-10-28-diwali-ordering-event"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/events/pick-up-for-friday-11-03-diwali-ordering-event"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/vegetable-korma-w-tofu-contains-cashews"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/shabnam-ke-moti"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/vegetable-biryani"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/lamb-biryani"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/kashmiri-pulao"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/spinach-naan"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/files/masala-of-india-valentines-menu-pdf.pdf"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/chicken-seekh-kabab"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/files/masala-christmas-buffet-2-pdf-pdf-2.pdf"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/items/lamb-seekh-kabab"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/files/masala-christmas-buffet-pdf.pdf"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/files/masala-christmas-buffet-1-pdf-2.pdf"
          element={<Navigate to="/order-online" replace />}
        />
        <Route
          path="/files/valentines-menu-pdf.pdf"
          element={<Navigate to="/order-online" replace />}
        />
        <Route path="/items/methi" element={<Navigate to="/order-online" replace />} />

        <Route path="/order" element={<Navigate to="/order-online" replace />} />
        <Route path="/onlineorder" element={<Navigate to="/order-online" replace />} />

        {/* ******* End Redirection Url Paths ************ */}

        {/* {process.env.REACT_APP_DESIGN === "DESIGN1" ? */}
        <Route exact path="/" element={<CommonHome />}></Route>
        {/* :
          <Route exact path="/" element={<ComingSoon />}></Route>} */}
        <Route exact path="/recipe-detail" element={<RecipeDetail />}></Route>
        <Route exact path="/dashboard" element={<CustomerDashboard />}></Route>
        <Route
          exact
          path="/payment-successful"
          element={<PaymentSuccess />}
        ></Route>
        <Route exact path="/menu" element={<Home />}></Route>
        <Route exact path="/order-online" element={<Home />}></Route>
        <Route exact path="/privacy" element={<Privacy />}></Route>
        <Route
          exact
          path="/terms-condition"
          element={<TermsCondition />}
        ></Route>
        <Route exact path="/disclaimer" element={<Disclaimer />}></Route>
        <Route exact path="/reservation" element={<ResrvationPage />}></Route>
        <Route exact path="/gallery" element={<Cropped />}></Route>
        <Route exact path="/chef" element={<ChefPage />}></Route>
        <Route exact path="/catering" element={<Catering />}></Route> 
        <Route exact path="/contact" element={<Contact />}></Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* <div className="d-lg-block d-md-block d-sm-none d-none">
        <Footer />
      </div> */}
    </div>
  );
}

export default App;
