import moment from "moment";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FssaiImg from "../../../assets/img/FSSAI_logo.png";
import GrubDigestLogo from "../../../assets/img/logo-grubdigest.png";
import { store } from "../../../pages/context/store";
import "./MasalaFooter.css";
import FooterShape from "../../Masala-of-india/assets/images/footer/footer-shape.png";
import Fleft from "../../Masala-of-india/assets/images/footer/f-left.png";
import Fright from "../../Masala-of-india/assets/images/footer/f-right.png";
import Phonenumber from "../../Masala-of-india/assets/images/footer/ii1.png";
import Location from "../../Masala-of-india/assets/images/footer/ii2.png";
import Email from "../../Masala-of-india/assets/images/footer/ii3.png";
import logo from "../../Masala-of-india/assets/images/logo/masala-of-india.png";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

function Footer() {
  const {
    fssai,
    setFssai,
    restaurantName,
    cardAccepted,
    setcardAccepted,
    clientDetails,
  } = useContext(store);

  const { restaurantAddress, setRestaurantAddress } = useContext(store);
  const { restaurantTime, setRestaurantTime } = useContext(store);
  return (
    <>
      <div className="footer-section">
        {/* <img
          className="f-left wow fadeInLeft"
          data-wow-delay=".5s"
          src={Fleft}
          alt=""
        />
        <img
          className="f-right wow fadeInRight"
          data-wow-delay=".5s"
          src={Fright}
          alt=""
        /> */}
        <div className="container">
          <div className="row justify-content-center">
            {/* <div className="col-md-8">
              <div className="top-area">
                <div className="footer-img-box">
                  <img src={logo} alt="masala-of-india" />
                </div>
                <div className="footer-social-links">
                  <span className="label">Follow us :</span>
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/masalaofindiacuisine/">
                        <i className="fab fa-facebook-f">
                          <FaFacebookF className="social-icon" />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/masala_of_india/?hl=en">
                        <i className="fab fa-twitter">
                          <FaInstagram className="social-icon" />
                        </i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-md-12 mt-4">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="info-box">
                    <div className="icon">
                      <img src={Phonenumber} alt="" />
                    </div>
                    <p className="text-white text-center">Phone Number</p>
                    <p className="text-white text-center">
                      <a
                        href={`tel:${clientDetails[0]?.fld_phonenumber}`}
                        className="text-white">
                        {clientDetails[0]?.fld_phonenumber}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="info-box">
                    <div className="icon">
                      <img src={Location} alt="" />
                    </div>
                    <p className="text-white text-start border-bottom pb-2 mb-2">
                      <b className="mb-1 d-block">Branch 1</b>
                      {clientDetails[0]?.fld_address},
                    </p>
                    <p className="text-white text-start">
                      <b className="mb-1 d-block">Branch 2</b>
                      8515 35th Ave NE Unit #C Seattle, WA 98115 United States
                    </p>
                    {/* <p className="text-white text-center">{restaurantAddress}</p> */}
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="info-box">
                    <div className="icon">
                      <img src={Email} alt="" />
                    </div>
                    <p className="text-white text-center">Tuesday - Sunday</p>
                    <p className="text-white text-center">
                      10:00 am - 10:00 pm
                      {/* {restaurantTime} */}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="footer-social-links text-center">
                    <ul>
                      <li>
                        <a
                          href={clientDetails[0]?.fld_facebook}
                          target="_blank">
                          {/* <i className="fab fa-facebook-f"> */}
                          <FaFacebookF className="social-icon" />
                          {/* </i> */}
                        </a>
                      </li>
                      <li>
                        <a
                          href={clientDetails[0]?.fld_instagram}
                          target="_blank">
                          {/* <i className="fab fa-twitter"> */}
                          <FaInstagram className="social-icon" />
                          {/* </i> */}
                        </a>
                      </li>
                    </ul>
                    <p className="text-white text-center">
                      Let's Connect Socially
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row copyright-text">
            <div className="col-xl-6">
              <ul className="footer-nav w-100 text-center text-xl-start justify-content-center justify-content-xl-start mb-2 mb-lg-2 mb-xl-0">
                <li>
                  <a href="/terms-condition">Terms and Conditions</a>
                </li>
                <li>
                  <a href="/privacy">Privacy Policy</a>
                </li>
                {/* <li>
                  <a href="">FAQ</a>
                </li> */}
                <li>
                  <a href="/disclaimer">Disclaimer</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="col-xl-6 text-center text-xl-end pb-0 pb-sm-4 pb-lg-0">
              Copyright © 2024 {clientDetails[0]?.fld_restaurantname}.
              <br className="d-block d-md-none" /> All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid footer-bottom-company-name mb-lg-0 pb-4 pb-md-0">
        {/* <div className="container"> */}
        <div className="row">
          <div className="col-12 col-lg-6 text-lg-start text-center text-white pt-2 pb-1 py-lg-3">
            Powered by Grub Digest - <br className="d-block d-md-none" /> A
            Product of
            <a
              className="text-white"
              href="https://globaltrendz.com/"
              target="blank">
              &nbsp; Global Trendz
            </a>
          </div>
          {/* <div className="col-12 col-md-6 text-md-end text-center text-white py-1 py-lg-3">
              <a
                className="text-white"
                href="https://grubdigest.com/"
                target="blank">
                {" "}
                Powered By Grub Digest
              </a>
            </div> */}
        </div>
        {/* </div> */}
      </div>
    </>
  );
}
export default Footer;
