import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container, Row
} from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import femaleAvatar from "../../assets/img/femaleAvatar.jpg";
import maleAvatar from "../../assets/img/maleAvatar.jpg";
import PostApiCall from "../../helper/PostAPI";
import { store } from "../../pages/context/store";
import "./CustomerDashboard.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper/modules";
import AddressOffcanvas from "../../components/AddressOffCanvas/AddressOffcanvas";
import OrderHistoryDrawer from "../../components/OrderHistoryDrawer/OrderHistoryDrawer";
import { Link, useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import CommonHomeNavbar from "../Masala-of-india/Navbar/CommonHomeNavbar";

const CustomerDashboard = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showOrderHistory, setShowOrderHistory] = useState(false);
  const [addressLabel, setAddressLabel] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const { customerData, setCustomerData, setShowAddressModal, showAddressModal } = useContext(store);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [addressId, setAddressId] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [Gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [doa, setDoa] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShowAddressModal(true);
  const handleShowOrderHistory = () => setShowOrderHistory(true);
  const [customerFrequentlyOrder, setCustomerFrequentlyOrder] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const { currencyCode, setCurrencyCode } = useContext(store);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
  }, []);

  useEffect(() => {
    const login = localStorage.getItem("LoginDetailsWeb");
    const loginDetails = login ? JSON.parse(login)[0] : "";
    if (login) {
      getCustomerData();
      PostApiCall.postRequest(
        {
          columns: "*",
          whereClause: ` where fld_customerid = ${localStorage.getItem("LoginDetailsWeb") == null
            ? null
            : loginDetails.CustomerId
            }`,
        },
        "getCustomerAddress"
      ).then((result) =>
        result?.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            setCustomerAddress(obj.message);
          }
        })
      );
      PostApiCall.postRequest(
        {
          customerid: loginDetails.CustomerId,
        },
        "getCustomerOrderList"
      ).then((result) =>
        result?.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            PostApiCall.postRequest(
              {
                WhereClause: `where fld_customer_id = ${loginDetails.CustomerId}`,
              },
              "getCustomerFrequentlyOrder"
            ).then((results1) =>
              results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                  setCustomerFrequentlyOrder(obj1.data);
                }
              })
            );
            setCustomerOrders(obj.data);
          }
        })
      );
    } else {
      navigate('/')
    }

  }, []);

  const getCustomerData = () => {
    const login = localStorage.getItem("LoginDetailsWeb");
    const loginDetails = login ? JSON.parse(login)[0] : "";
    PostApiCall.postRequest(
      {
        name: loginDetails?.fld_name,
        email: loginDetails?.fld_email,
      },
      "getCustomerData"
    ).then((result) =>
      result?.json().then((obj3) => {
        if (result.status == 200 || result.status == 201) {
          if (
            obj3.message[0].fld_gender == "Male" ||
            obj3.message[0].fld_gender == null
          ) {
            setProfileImg(maleAvatar);
          } else {
            setProfileImg(femaleAvatar);
          }
          setCustomerData(obj3.message);
          setMobileNo(obj3.message[0]?.fld_mobile)
          setEmail(obj3.message[0]?.fld_email)
          setGender(obj3.message[0]?.fld_gender)
          setDob(obj3.message[0]?.fld_dateofbirth)
          setDoa(obj3.message[0]?.fld_doa)
          setName(obj3.message[0]?.fld_name)
        }
      })
    );
  }

  const addcustomer = () => {
    PostApiCall.postRequest(
      {
        customerid: customerData[0]?.CustomerId,
        name: name,
        mobile: mobileNo,
        dob: dob,
        gender: Gender,
      },
      "updateCustomerProfile"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          setEditProfile(false)
          getCustomerData();
        }
      })
    );
  }
  // console.log(showAddressModal)
  return (
    <>
      <CommonHomeNavbar />
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-header-box">
                <h1 class="text-anime">Dashboard</h1>
                <nav>
                  <ol class="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Dashboard</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mt-4 mt-lg-5">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="dash-heading">
                <h5 className="fw-bold text-danger">Personal Details</h5>
                <p>Welcome {customerData[0]?.fld_name}</p>
              </div>
            </Col>
            <Col lg={6} className="mt-4">

              {editProfile === false ?
                <Card className="shadow border-light customer-dashboard-card">
                  <Card.Body className="d-lg-flex align-items-center">
                    <div className="text-lg-left text-center">
                      <Card.Img src={profileImg}></Card.Img>
                      <h5 className="fw-bold text-black mt-3 text-center"></h5>
                    </div>
                    <div className="d-flex flex-column w-100 px-lg-5 px-2">
                      <div className="d-flex align-items-center mb-lg-4 mb-2">
                        <h6 className="fw-bold text-black w-50 mb-0">
                          Mobile Number
                        </h6>
                        <p className="text-black w-50 mb-0">
                          {customerData[0]?.fld_mobile}
                        </p>
                      </div>
                      <div className="d-flex align-items-center mb-lg-4 mb-2">
                        <h6 className="fw-bold text-black w-50 mb-0">Name</h6>
                        <p className="text-black w-50 mb-0">
                          {customerData[0]?.fld_name}
                        </p>
                      </div>
                      <div className="d-flex align-items-center mb-lg-4 mb-2">
                        <h6 className="fw-bold text-black w-50 mb-0">Gender</h6>
                        <p className="text-black w-50 mb-0">
                          {customerData[0]?.fld_gender}
                        </p>
                      </div>
                      <div className="d-flex align-items-center mb-lg-4 mb-2">
                        <h6 className="fw-bold text-black w-50 mb-0">
                          Date of Birth
                        </h6>
                        <p className="text-black w-50 mb-0">
                          {customerData[0]?.fld_dateofbirth}
                        </p>
                      </div>
                      {/* <div className="d-flex align-items-center">
                        <h6 className="text-black fw-bold mb-0 w-50">
                          Date of Anniversary
                        </h6>
                        <p className="text-black w-50 mb-0">
                          {customerData[0]?.fld_doa}
                        </p>
                      </div> */}
                    </div>
                  </Card.Body>
                  <button onClick={() => { setEditProfile(!editProfile) }} className="customer-dashboard-edit-btn"><AiOutlineEdit /></button>
                  {/* <Link to="/order-online" className="btn btn-md btn-danger logout-btn" onClick={()=>{
                localStorage.removeItem("LoginDetailsWeb")
              }}>Logout</Link> */}

                </Card>
                :
                <Card className="shadow border-light customer-dashboard-card position-relative">
                  <Card.Body className="d-lg-flex align-items-center">
                    <div className="text-lg-left text-center">
                      <Card.Img src={profileImg}></Card.Img>
                      <h5 className="fw-bold text-black mt-3 text-center"></h5>
                    </div>
                    <div className="d-flex flex-column w-100 px-lg-5 px-2">
                      <div className="d-flex align-items-center mb-lg-4 mb-2">
                        <h6 className="fw-bold text-black w-50 mb-0">
                          Mobile Number
                        </h6>
                        <p className="text-black w-50 mb-0">
                          <input
                            className="form-control form-control-lg"
                            onChange={(e) => setMobileNo(e.target.value)}
                            value={mobileNo} />
                        </p>
                      </div>
                      <div className="d-flex align-items-center mb-lg-4 mb-2">
                        <h6 className="fw-bold text-black w-50 mb-0">Name</h6>
                        <p className="text-black w-50 mb-0">
                          <input
                            className="form-control form-control-lg"
                            onChange={(e) => setName(e.target.value)}
                            value={name} />
                        </p>
                      </div>
                      <div className="d-flex align-items-center mb-lg-4 mb-2">
                        <h6 className="fw-bold text-black w-50 mb-0">Gender</h6>
                        <p className="text-black w-50 mb-0">
                          <select
                            value={Gender}
                            onChange={(e) => { setGender(e.target.value) }}
                            className="form-control form-control-lg">
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          {/* <input value={customerData[0]?.fld_gender} /> */}
                        </p>
                      </div>
                      <div className="d-flex align-items-center mb-lg-4 mb-2">
                        <h6 className="fw-bold text-black w-50 mb-0">
                          Date of Birth
                        </h6>
                        <p className="text-black w-50 mb-0">
                          <input
                            type="date"
                            className="form-control form-control-lg"
                            onChange={(e) => setDob(e.target.value)}
                            value={dob} />
                        </p>
                      </div>
                      {/* <div className="d-flex align-items-center">
                        <h6 className="text-black fw-bold mb-0 w-50">
                          Date of Anniversary
                        </h6>
                        <p className="text-black w-50 mb-0">
                          <input
                            className="form-control form-control-lg"
                            onChange={(e) => setDoa(e.target.value)}
                            value={doa} />
                        </p>
                      </div> */}
                      <Button
                        variant="white"
                        onClick={() => {
                          addcustomer();
                        }}
                        className="btn-danger height-fit mt-2">
                        Save
                      </Button>
                    </div>
                  </Card.Body>
                  <button onClick={() => { setEditProfile(!editProfile) }} className="customer-dashboard-edit-btn"><IoCloseOutline /></button>
                </Card>
              }
            </Col>
            {/* <Col lg={4} className="mt-4">
              {/* <div className="dash-heading mb-3">
                <h5 className="fw-bold text-black"></h5>
                <p>Favorite Food Items</p>
              </div>
              <Card className="shadow customer-dashboard-card h-100 profile-box">
                <Card.Body className="d-flex justify-content-between flex-column p-lg-3 p-3">
                  <div className="d-flex justify-content-between ">
                    <div className="profile-box-icon">
                      <MdOutlineLoyalty />
                    </div>
                    <div className="text-end">
                      <h2 className="fw-bold text-white">Loyalty Points</h2>
                      <h4 className="fw-bold text-white">100 Points</h4>
                    </div>
                  </div>
                  <div>
                    <h6 className="fw-bold text-white">
                      Placed 20 Orders Worth <BiRupee />
                      10,500
                    </h6>
                    <h4 className="fw-bold text-white">
                      Saved <BiRupee />
                      1500 so far
                    </h4>
                    <p className="fw-bold text-white border-top py-2 mb-0">
                      Member Since 20<sup>th</sup> Jan 2023
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
            <Col lg={12} className="mt-5">
              <div className="d-flex justify-content-between align-items">
                <div className="dash-heading">
                  <h5 className="fw-bold text-danger">Address Book</h5>
                  <p>List of Delivery Addresses</p>
                </div>
                {/* <Button
                  variant="white"
                  onClick={() => {
                    setShowAddressModal(true)
                    setAddressId(null);
                  }}
                  className="btn-danger height-fit">
                  Add New Address
                </Button> */}
              </div>
              <Swiper
                //   freeMode={true}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="mySwiper pb-5 pt-3 px-2">
                {customerAddress.map((address) => {
                  return (
                    <SwiperSlide>
                      <Card className="shadow border-light customer-dashboard-card customer-dashboard-address-card">
                        <Card.Body>
                          <Card.Text className="h-100 d-flex flex-column justify-content-between">
                            <div>
                              <h5 className="fw-bold text-black">
                                {address.fld_name}
                              </h5>
                              <p>
                                {address.fld_address_1 +
                                  " " +
                                  address.fld_address_2 +
                                  " " +
                                  address.fld_address_3 +
                                  " " +
                                  address.fld_pincode}
                              </p>
                              <p className="text-black fw-bold mt-1">
                                Landmark
                              </p>
                              <p>
                                {address.fld_landmark}
                              </p>
                            </div>
                            <div className="cus-das-btn border-top pt-3">
                              <p>{address.fld_address_type}</p>
                              {/* <AiOutlineEdit
                                onClick={() => {
                                  handleShow();
                                  setAddressId(address.fld_address_id);
                                }}
                              /> */}
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Col>
            <Col lg={12} className="my-4">
              <div className="dash-heading mb-3">
                <h5 className="fw-bold text-danger">Order History</h5>
                <p>List of Orders Placed</p>
              </div>
              <Card className="shadow border-light recent-order-box">
                <Card.Body>
                  <Card.Text>
                    <div className="d-flex justify-content-between align-items-center mb-3 recent-inner">
                      <div className="dash-heading">
                        <h5 className="fw-bold text-black">Recent Orders</h5>
                        <p>Latest 10 New Orders</p>
                      </div>
                    </div>
                    <div className="order-list-view-main">
                      {customerOrders.slice(0, 10).map((order) => {
                        return (
                          <div className="order-list-view" onClick={() => {
                            setOrderId(order.fld_cart_id);
                            handleShowOrderHistory();
                          }}>
                            <div className="d-flex justify-content-start gap-3 order-list-view-box-no">
                              <div>
                                <div className="d-flex">
                                  <h6 className="me-2">Order No.</h6>
                                  <h6 className="text-danger">
                                    {order.fld_order_number}
                                  </h6>
                                </div>
                                <p>
                                  {order.fld_cart_date +
                                    "" +
                                    order.fld_cart_time}
                                </p>
                              </div>
                            </div>
                            {order.fld_order_type != "TakeAway" ?
                              <div className="recent-orders-name order-list-view-box">
                                <h6 className="fw-bold">{order.customer_name}</h6>
                                <p>
                                  {order.fld_address_1 + " " + order.fld_address_1}
                                </p>
                              </div>
                              : ""}
                            <div>
                              <h6 className="fw-bold">
                                {currencyCode}
                                {order.Total_Inclusive_Tax}
                              </h6>
                              <p>{order.total_items} Items</p>
                            </div>
                            <div className="order-list-view-box">
                              <h6 className="fw-bold">Order Type</h6>
                              <p>{order.fld_order_type}</p>
                            </div>
                            <div className="order-list-view-box">
                              <h6 className="fw-bold">Payment Details</h6>
                              <p>{order.fld_payment_mode}</p>
                            </div>

                            <div className="order-item-options ">
                              <Button
                                variant="white"

                                className="recent-order-icon"
                                id="dropdown-basic">
                                <BsFillArrowRightCircleFill />
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {customerFrequentlyOrder.length > 0 ?
              <>
                <Col lg={12} className="mt-4">
                  <div className="dash-heading mb-3">
                    <h5 className="fw-bold text-danger">
                      Frequently Ordered Items
                    </h5>
                    <p>Favorite Food Items</p>
                  </div>
                </Col>
                <Col lg={12}>
                  <Swiper
                    //   freeMode={true}
                    loop={true}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[FreeMode, Pagination]}
                    className="mySwiper pb-5 pt-3">
                    {customerFrequentlyOrder?.map((frequentlyOrder) => {
                      return (
                        <SwiperSlide>
                          <Card className="shadow border-light frequently-order-card">
                            <Card.Img
                              variant="top"
                              src={frequentlyOrder.FLD_Image}
                            />
                            <Card.Body>
                              <Card.Title className="border-bottom pb-2">
                                {frequentlyOrder.FLD_Name}
                              </Card.Title>
                              <Card.Text>
                                Bought {frequentlyOrder.fld_item_count} times
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Col>
              </>
              : ""}
          </Row>
        </Container>
      </section>

      <AddressOffcanvas
        customerAddress={customerAddress}
        show={show}
        addressId={addressId}
        setShow={setShow}
      />

      <OrderHistoryDrawer
        offcanvasshow={showOrderHistory}
        offcanvasclose={setShowOrderHistory}
        orderId={orderId}
      />
    </>
  );
};

export default CustomerDashboard;
