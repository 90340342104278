import "animate.css";
import "../assets/css/style.css";
import "./Testimonial.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BigCircal from "../assets/images/testimonial/big-circle.png";
import SmallCircal from "../assets/images/testimonial/smallcircle.png";
import { FaQuoteRight, FaQuoteLeft } from "react-icons/fa";
import { IoStar } from "react-icons/io5";

const Testimonial = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  return (
    <>
      <section className="testimonial-section mt-md-5 mt-4 testimonial-phn-bottom-response">
        <div className="container-md container-fluid px-0 px-3 py-4">
          <div className="row">
            <div className="col-xl-6 offset-xl-1 col-lg-7 order-last order-lg-first position-relative">
              <div className="big-circle-img position-absolute">
                <FaQuoteRight className="right-quote" />
              </div>
              <div className="small-circle-img position-absolute">
                <FaQuoteLeft className="left-quote" />
              </div>
              <div className="testimonial-slider">
                <Slider {...settings} className="testimonial-slider-dots">
                  <div>
                    <p className="masala-common-content mb-0 pb-0">
                      Fantastic Indian food! Every dish is distinctive in taste
                      from other dishes and the quality of ingredients is
                      notable. Our family are loyal and order from Masala of
                      India for most birthdays and special occasions - even
                      after moving out of the neighborhood. My favorites are the
                      saag paneer, veggie korma, and vegetarian mixed appetizer.
                      The meat-eaters in the family love the chicken jalfrezie,
                      chicken masala and the Goa curry . The vindaloo is very
                      tasty, too!
                    </p>
                    <p className="masala-common-content text-center">
                      <div className="start-color">
                        <IoStar />
                        <IoStar />
                        <IoStar />
                        <IoStar />
                        <IoStar />
                      </div>
                      <strong>62rubies</strong>
                    </p>
                  </div>
                  <div>
                    <p className="masala-common-content mb-0 pb-0">
                      I've been here pre-pandemic when they still had the
                      buffet, and post pandemic after they quit the buffet. That
                      almost caused me to skip dining here. Now I see they are
                      finally going to re-open the buffet. About time! This is
                      the best Indian restaurant North of Seattle and to finally
                      see they are re-starting their buffet will save their
                      asses.
                    </p>
                    <p className="masala-common-content text-center">
                      <div className="start-color">
                        <IoStar />
                        <IoStar />
                        <IoStar />
                        <IoStar />
                        <IoStar />
                      </div>
                      <strong>Kurt</strong>
                    </p>
                  </div>

                  <div>
                    <p className="masala-common-content mb-0 pb-0">
                      This is a restaurant feels like you are eating in India.
                      Taste and the flavors are great and we love here as a
                      whole family.
                    </p>
                    <p className="masala-common-content text-center">
                      <div className="start-color">
                        <IoStar />
                        <IoStar />
                        <IoStar />
                        <IoStar />
                        <IoStar />
                      </div>
                      <strong>Hilal Cil</strong>
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="col-xl-4 mb-5 mb-xl-0 offset-xl-1 col-lg-5 align-self-center">
              <div className="section-header">
                <h2>Testimonials</h2>
                <h3>What our clients say</h3>
                <button type="submit" className="review-btn">
                  <a className="text-white"
                    href="https://maps.app.goo.gl/dFFwJPRSKDB2mfmn8"
                    target="blank"
                  >
                    {" "}
                    Leave a Review{" "}
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
