import { Col, Container, Row } from "react-bootstrap";
import "./DeliveryPartners.css";
import "animate.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SwiggyImage from "../../../assets/img/swiggy.png";
import ZomatoImage from "../../../assets/img/zomato.png";
import DunzoImage from "../../../assets/img/dunzo.png";

import BeyondMenu from "../../../assets/img/delivery-partners/bm.png";
import DoorDash from "../../../assets/img/delivery-partners/doordash.webp";
import EzCater from "../../../assets/img/delivery-partners/ezcater.webp";
import Grubhub from "../../../assets/img/delivery-partners/grubhub.webp";
import Qmenu from "../../../assets/img/delivery-partners/qmenu.png";
import Toast from "../../../assets/img/delivery-partners/toast.webp";
import TouchBistro from "../../../assets/img/delivery-partners/touch_bistro.png";
import UberEats from "../../../assets/img/delivery-partners/uber.webp";
import Waiter from "../../../assets/img/delivery-partners/waiter.webp";
const CommonHomeDeliveryPartners = () => {
  const deliverypartnersbanner = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 5,
      showDots: false,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      showDots: false,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2,
      showDots: false,
    },
  };
  return (
    <>
      <section className="py-5 mt-5">
        <Container>
          <Col lg={12}>
            <div className="section-header mb-0 mb-lg-4">
              <h2>Navigating Excellence</h2>
              <h3>Meet our delivery partners</h3>
            </div>
          </Col>
          <Row className="align-items-center justify-content-center h-100 delivery-section">
            <Col lg={12}>
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={deliverypartnersbanner}
                ssr={true}
                infinite={true}
                autoPlay={true}
                arrows={false}
                autoPlaySpeed={3000}>
                <div className="text-center">
                  <div className="testimonial-slid">
                    <a
                      href="https://www.beyondmenu.com/23423/seattle/masala-of-india-cuisine-seattle-98125.aspx"
                      target="_blank">
                      <img src={BeyondMenu} className="img-fluid" />
                    </a>
                  </div>
                </div>
                <div className="text-center">
                  <div className="testimonial-slid">
                    <a
                      href="https://www.doordash.com/store/masala-of-india-seattle-43066/"
                      target="_blank">
                      <img src={DoorDash} className="img-fluid" />
                    </a>
                  </div>
                </div>
                <div className="text-center">
                  <div className="testimonial-slid">
                    <a
                      href="https://www.ezcater.com/catering/masala-of-india-cuisine-3"
                      target="_blank">
                      <img src={EzCater} className="img-fluid" />
                    </a>
                  </div>
                </div>
                <div className="text-center">
                  <div className="testimonial-slid">
                    <a
                      href="https://www.grubhub.com/restaurant/masala-of-india-cuisine-507-ne-northgate-way-seattle/76895"
                      target="_blank">
                      <img src={Grubhub} className="img-fluid" />
                    </a>
                  </div>
                </div>
                <div className="text-center">
                  <div className="testimonial-slid">
                    <a
                      href="https://qmenu.us/#/masala-of-india-seattle"
                      target="_blank">
                      <img src={Qmenu} className="img-fluid" />
                    </a>
                  </div>
                </div>
                {/* <div className="text-center">
                  <div className="testimonial-slid">
                    <a href="">
                      <img src={Toast} className="img-fluid" />
                    </a>
                  </div>
                </div> */}
                {/* <div className="text-center">
                  <div className="testimonial-slid">
                    <a
                      href="https://order.tbdine.com/pickup/40999"
                      target="_blank">
                      <img src={TouchBistro} className="img-fluid" />
                    </a>
                  </div>
                </div> */}
                <div className="text-center">
                  <div className="testimonial-slid">
                    <a
                      href="https://www.ubereats.com/store/masala-of-india-cuisine/mNXqyoblR9SphEg0tFm7Rw?diningM[…]TEyMi4zMjIxMDY3JTdE&rpu=d6cbff66-27bc-4dc7-bc8a-6f8e2767ecbd"
                      target="_blank">
                      <img src={UberEats} className="img-fluid" />
                    </a>
                  </div>
                </div>
                <div className="text-center">
                  <div className="testimonial-slid">
                    <a href="https://www.waiter.com/takeout-delivery/masala-of-india/seattle-wa/507-ne-northgate-way">
                      <img src={Waiter} className="img-fluid" />
                    </a>
                  </div>
                </div>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2684.7641288528885!2d-122.3227778!3d47.7084167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDfCsDQyJzMwLjMiTiAxMjLCsDE5JzIyLjAiVw!5e0!3m2!1sen!2sin!4v1709363474613!5m2!1sen!2sin"
        width="100%"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </>
  );
};

export default CommonHomeDeliveryPartners;
