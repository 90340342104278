import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import {
  Container, Nav,
  Navbar, Offcanvas, ProgressBar, Toast
} from "react-bootstrap";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import ReactStars from "react-rating-stars-component";
import { useSearchParams } from "react-router-dom";
import HalalIcon from "../../../assets/img/halal.svg";
import grublogo from "../../../assets/img/logo-grubdigest.png";
import RestaurantStatus from "../../../components/RestaurantStatus/RestaurantStatus";
import RestaurantTopBar from "../../../components/RestaurantTopBar/RestaurantTopBar";
import GetApiCall from "../../../helper/GetApi";
import PostApiCall from "../../../helper/PostAPI";
import Logo from "../../../pages/Masala-of-india/assets/images/logo/masala-of-india.png";
import { store } from "../../context/store";
import "./HomeNavbar.css";

function CommonHomeNavbar() {
  const [scroll, setScroll] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    setKeyStrings,
    setShowLoginModal,
    setClientDetails,
    clientDetails,
    restaurantOpen,
    setRestaurantOpen,
    setCurrencyCode,
    setFssai,
    setRestaurantAddress,
    setRestaurantTime,
    setRestaurantName,
    setCustomerValidation,
    setClientLogo,
  } = useContext(store);
  const handleCloseMenu = () => setShowMenu(false);
  const handleShowMenu = () => setShowMenu(true);
  const handleShowLoginModal = () => setShowLoginModal(true);
  const [showRating, setShowRating] = useState([false]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [loginDisabled] = useState(
    localStorage.getItem("LoginDetailsWeb") != null && localStorage.getItem("SessionExpired") === null ? false : true
  );

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };
  const toggleShowRating = (index) => {
    const newShow = [...showRating];
    newShow[index] = !newShow[index];
    setShowRating(newShow);
  };
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }

    if (window.pageYOffset > 0) {
      setScroll("scrollSticky");
    } else {
      window.addEventListener("scrollSticky", () => {
        setScroll(window.scrollY > 0);
      });
    }
    PostApiCall.postRequest(
      {
        id: "",
      },
      "getClientData"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setClientData(obj1.message);
          setClientDetails(obj1.message);
          setRestaurantOpen(obj1.message[0]?.restaurant_open);
          setCurrencyCode(obj1.message[0].fld_currency);
          setFssai(obj1.message[0].fld_fssai_number);
          setRestaurantAddress(
            obj1.message[0].fld_address +
            ", " +
            obj1.message[0].city_name +
            ", " +
            obj1.message[0].state_name
          );
          setRestaurantTime(obj1.message[0].res_timings);
          setRestaurantName(obj1.message[0].fld_restaurantname);
          setShowRating(Array(obj1.message.length).fill(false));
          setCustomerValidation(obj1.message[0].fld_customer_validation_using);
          setClientLogo(obj1.message[0]?.fld_image);
          // Notiflix.Loading.Remove();
        }
      })
    );
    GetApiCall.getRequest("getKeyStrings").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setKeyStrings(obj1.data);
          Notiflix.Loading.Remove();
        }
      })
    );
  }, []);

  return (
    <>
      {restaurantOpen === "false" ? <RestaurantStatus /> : <RestaurantTopBar />}
      <section
        className={
          scroll
            ? "shadow-sm my-0 py-2 fixed-top top-bar pe-0"
            : "nav-banner-bg shadow-none my-0 py-2 pe-0"
        }
        expand="false"
      >
        <div className="container-fluid position-relative px-0 mx-0 z-3">
          <Navbar key="xl" expand="xl" className="py-2">
            <Container fluid className="px-sm-5 px-3 toggle-position-phnview">
              <Navbar.Brand
                href="/"
                className="position-absolute top-0 logo-position z-3 nav-brand-size mx-0 logo-tab"
              >
                <a
                  href="/catering"
                  // onClick={toggleOffcanvas}
                  className="text-white tab-view-item"
                >
                  Catering
                </a>

                <a
                  href="/menu"
                  className="text-white tab-view-item"
                // onClick={toggleOffcanvas}
                >
                  Menu
                </a>

                <a
                  href="/order-online"
                  className="text-white tab-view-item"
                // onClick={toggleOffcanvas}
                >
                  Order Online
                </a>

                <img
                  src={Logo}
                  className="logo-size mx-0 me-4"
                  alt={clientData[0]?.fld_restaurantname}
                />
              </Navbar.Brand>
              {/* <Navbar.Toggle
                aria-controls="offcanvasNavbar-expand-md"
                className="text-end bg-white text-dark"
              /> */}
              <Navbar.Toggle
                aria-controls="offcanvasNavbar-expand-md"
                className="text-end bg-white text-dark"
                onClick={toggleOffcanvas}
              />
              <Navbar.Offcanvas
                show={showOffcanvas}
                onHide={() => setShowOffcanvas(false)}
                id="offcanvasNavbar-expand-md"
                aria-labelledby="offcanvasNavbarLabel-expand-md"
                placement="start"
                className="phn-view-sidebar"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id="offcanvasNavbarLabel-expand-md">
                    <img
                      src={Logo}
                      className="logo-size"
                      alt={clientData[0]?.fld_restaurantname}
                    />
                  </Offcanvas.Title>
                  {clientData != null
                    ? clientData.map((cd, index) => {
                      return (
                        <div
                          onClick={() => {
                            toggleShowRating(index);
                          }}
                          className="d-flex justify-content-start justify-content-lg-end mobile-review-box"
                        >
                          <a
                            className="btn-grub w-auto cursor-pointer"
                            onClick={() => {
                              toggleShowRating(index);
                            }}
                          >
                            <p className="rest-rating-reviews mt-2">
                              <p className="mb-1">{cd.rating_count}</p>
                              <p className="mb-0">Reviews</p>
                            </p>
                            <ReactStars
                              count={5}
                              value={cd.FLD_Rating}
                              size={20}
                              edit={false}
                              isHalf={true}
                              activeColor="#ffd700"
                              classNames="cursor-pointer"
                            />
                          </a>
                          <Toast
                            // show={showRating}
                            show={showRating[index]}
                            onClose={() => toggleShowRating(index)}
                            className="rating-popup homepage"
                          >
                            <Toast.Header className="justify-content-between">
                              <div className="d-flex flex-column">
                                <div className="d-flex align-items-center gap-2">
                                  <ReactStars
                                    count={5}
                                    value={cd.FLD_Rating}
                                    size={20}
                                    onChange={(rating) => {
                                      var login =
                                        localStorage.getItem("LoginDetailsWeb");
                                      var logindetails = login
                                        ? JSON.parse(login)[0]
                                        : "";
                                      if (login === undefined) {
                                        handleShowLoginModal();
                                      } else {
                                        PostApiCall.postRequest(
                                          {
                                            rating: rating,
                                            userId:
                                              localStorage.getItem(
                                                "LoginDetailsWeb"
                                              ) == null
                                                ? null
                                                : logindetails.CustomerId,
                                          },
                                          "UpdateRestaurantRating"
                                        ).then((result) =>
                                          result?.json().then((obj) => {
                                            if (
                                              result.status == 200 ||
                                              result.status == 201
                                            ) {
                                              PostApiCall.postRequest(
                                                {
                                                  id: parseInt(
                                                    searchParams.get(
                                                      "clientid"
                                                    )
                                                  ),
                                                },
                                                "getClientData"
                                              ).then((results) =>
                                                results
                                                  ?.json()
                                                  .then((obj1) => {
                                                    if (
                                                      results.status == 200 ||
                                                      results.status == 201
                                                    ) {
                                                      setClientData(
                                                        obj1.message
                                                      );
                                                      setClientDetails(
                                                        obj1.message
                                                      );
                                                      setShowRating(
                                                        Array(
                                                          obj1.message.length
                                                        ).fill(false)
                                                      );
                                                      Notiflix.Loading.Remove();
                                                    }
                                                  })
                                              );
                                              Notiflix.Notify.Success(
                                                "Thank you for rating."
                                              );
                                            } else {
                                              Notiflix.Notify.Failure(
                                                obj.message
                                              );
                                            }
                                          })
                                        );
                                      }
                                    }}
                                    edit={true}
                                    isHalf={false}
                                    activeColor="#ffd700"
                                  />
                                  <h6 className="fw-bold d-block mb-0">
                                    {cd.FLD_Rating} out of 5
                                  </h6>
                                </div>
                              </div>
                            </Toast.Header>
                            {cd.rating_breakup?.split("#").map((data) => {
                              return (
                                <Toast.Body className="bg-white">
                                  <div className="d-flex gap-2 align-items-center">
                                    <p className="mb-0 text-end text-dark">
                                      {data?.split(",")[0]} Star
                                    </p>
                                    <div>
                                      <ProgressBar
                                        variant="warning"
                                        now={data.split(",")[1]}
                                      />
                                    </div>
                                    <p className="mb-0 text-dark">
                                      {data.split(",")[1]}%
                                    </p>
                                  </div>
                                </Toast.Body>
                              );
                            })}
                          </Toast>
                          {/* <a
                              target="_blank"
                              href="#"
                              className="btn-grub"
                              rel="noreferrer">
                              <FaShareAlt className="btn-icon" />
                            </a>
                            <a
                              style={{
                                display:
                                  cd.fld_whatsappnumber != null
                                    ? "flex"
                                    : "none",
                              }}
                              target="_blank"
                              href={
                                cd.fld_phonenumber != null
                                  ? cd.fld_phonenumber
                                  : ""
                              }
                              className="btn-whatsappcall"
                              rel="noreferrer">
                              <FaWhatsapp className="btn-icon" />
                            </a>
                            <a
                              style={{
                                display:
                                  cd.fld_facebook != null ? "flex" : "none",
                              }}
                              target="_blank"
                              rel="noreferrer"
                              href={
                                cd.fld_facebook != null ? cd.fld_facebook : ""
                              }
                              className="btn-facebook">
                              <FaFacebookF className="btn-icon" />
                            </a>
                            <a
                              style={{
                                display:
                                  cd.fld_instagram != null ? "flex" : "none",
                              }}
                              target="_blank"
                              rel="noreferrer"
                              href={
                                cd.fld_instagram != null ? cd.fld_instagram : ""
                              }
                              className="btn-instagram">
                              <FaInstagram className="btn-icon" />
                            </a>
                            <a
                              style={{
                                display:
                                  cd.fld_youtube != null ? "flex" : "none",
                              }}
                              target="_blank"
                              rel="noreferrer"
                              href={
                                cd.fld_youtube != null ? cd.fld_youtube : ""
                              }
                              className="btn-youtube">
                              <FaYoutube className="btn-icon" />
                            </a>
                            <a
                              style={{
                                display: cd.fld_halal != null ? "flex" : "none",
                              }}
                              target="_blank"
                              rel="noreferrer"
                              href={cd.fld_halal != null ? cd.fld_halal : ""}>
                              <img
                                alt="halal"
                                src={HalalIcon}
                                className="halal-icon"
                              />
                            </a> */}
                        </div>
                      );
                    })
                    : ""}
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 gap-nav-list navbar-link-color">
                    {/*  Phone View */}
                    <Nav.Link
                      href="/"
                      onClick={toggleOffcanvas}
                      className="d-block d-lg-none"
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      href="/#about"
                      onClick={toggleOffcanvas}
                      className="d-block d-lg-none"
                    >
                      Our Story
                    </Nav.Link>
                    <Nav.Link
                      href="/menu"
                      className="d-block d-lg-none"
                      onClick={toggleOffcanvas}
                    >
                      Menu
                    </Nav.Link>
                    <Nav.Link
                      href="/reservation"
                      className="d-block d-lg-none"
                      onClick={toggleOffcanvas}
                    >
                      Reservation
                    </Nav.Link>
                    <Nav.Link
                      href="https://www.toasttab.com/masala-of-india-northgate/giftcards"
                      className="d-block d-lg-none"
                      onClick={toggleOffcanvas}
                      target="_blank"
                    >
                      Gift Cards
                    </Nav.Link>
                    <Nav.Link
                      href="/catering"
                      onClick={toggleOffcanvas}
                      className="d-block d-lg-none"
                    >
                      Catering
                    </Nav.Link>
                    <Nav.Link
                      href="/gallery"
                      onClick={toggleOffcanvas}
                      className="d-block d-lg-none"
                    >
                      Gallery
                    </Nav.Link>
                    <Nav.Link
                      href="/#holiday-special"
                      onClick={toggleOffcanvas}
                      className="d-block d-lg-none"
                    >
                      Special Offers
                    </Nav.Link>

                    {/* Desktop View View Navbar  */}
                    <Nav.Link href="/" className="d-none d-lg-block">
                      Home
                    </Nav.Link>
                    <Nav.Link href="/#about" className="d-none d-lg-block">
                      Our Story
                    </Nav.Link>
                    <Nav.Link href="/menu" className="d-none d-lg-block">
                      Menu
                    </Nav.Link>
                    <Nav.Link href="/reservation" className="d-none d-lg-block">
                      Reservation
                    </Nav.Link>
                    <Nav.Link
                      href="https://www.toasttab.com/masala-of-india-northgate/giftcards"
                      className="d-none d-lg-block"
                      target="_blank"
                    >
                      Gift Cards
                    </Nav.Link>
                    <Nav.Link href="/catering" className="d-none d-lg-block">
                      Catering
                    </Nav.Link>
                    <Nav.Link href="/gallery" className="d-none d-lg-block">
                      Gallery
                    </Nav.Link>
                    <Nav.Link
                      href="/#holiday-special"
                      className="d-none d-lg-block"
                    >
                      Special Offers
                    </Nav.Link>
                    {loginDisabled === false && window.location.pathname != "/" ? (
                      <Nav.Link
                        href="/order-online"
                        className="nav-link"
                        onClick={() => {
                          localStorage.removeItem("LoginDetailsWeb");
                        }}>
                        Logout
                      </Nav.Link>
                    ) : (
                      ""
                    )}
                    {/* <ul className="pc-sm-icon d-none d-lg-flex mb-0 p-0 me-4"> */}
                    <ul className="pc-sm-icon d-none d-xl-flex mb-0 p-0 me-4">
                      <li>
                        <a href={clientData[0]?.fld_facebook} target="_blank">
                          {/* <i className="fab fa-facebook-f"> */}
                          <FaFacebookF />
                          {/* </i> */}
                        </a>
                      </li>
                      <li>
                        <a href={clientData[0]?.fld_instagram} target="_blank">
                          {/* <i className="fab fa-twitter"> */}
                          <FaInstagram />
                          {/* </i> */}
                        </a>
                      </li>
                    </ul>
                  </Nav>

                  {/* <div className="d-block d-lg-none my-3 py-3 border-top border-bottom"> */}
                  <div className="d-block d-xl-none my-3 py-3 border-top border-bottom">
                    <a
                      href="/order-online"
                      className="onliner-order-desktop onliner-order-mobile btn-sm btn w-100 btn-tab-font"
                    >
                      Order Online
                    </a>
                  </div>
                  {/* <div className="mb-2 mt-1 mb-md-0 d-block d-lg-none"> */}
                  <div className="mb-2 mt-1 mb-md-0 d-block d-xl-none">
                    <p className="mb-1 mobile-nav-heading">Contact us</p>
                    <a href="tel:+2064171118" className="mobile-call-icon">
                      {" "}
                      <IoCall />
                      (206) 417-1118
                    </a>
                  </div>
                  {/* <div className="mb-2 mt-3 mb-md-0 d-block d-lg-none"> */}
                  <div className="mb-2 mt-3 mb-md-0 d-block d-xl-none">
                    <p className="mb-0 mobile-nav-heading">
                      Let's Connect Socially
                    </p>
                    <ul className="mobile-sm-icon">
                      <li>
                        <a href={clientData[0]?.fld_facebook} target="_blank">
                          {/* <i className="fab fa-facebook-f"> */}
                          <FaFacebookF />
                          {/* </i> */}
                        </a>
                      </li>
                      <li>
                        <a href={clientData[0]?.fld_instagram} target="_blank">
                          {/* <i className="fab fa-twitter"> */}
                          <FaInstagram />
                          {/* </i> */}
                        </a>
                      </li>
                    </ul>
                  </div>
                  {window.location.pathname != "/order-online" &&
                    <div className="text-lg-end d-none d-lg-block">
                      <a
                        href="/order-online"
                        // className="text-white onliner-order-desktop btn-lg rest-share-link d-md-block d-none"
                        className="text-white onliner-order-desktop btn-lg rest-share-link d-xl-block d-none"
                      >
                        Order Online
                      </a>
                    </div>}
                </Offcanvas.Body>
                <div className="sticky-bottom w-100 pb-4 d-flex flex-column justify-content-center align-items-center d-xl-none p-3">
                  {/* <div className="sticky-bottom w-100 pb-4 d-flex flex-column justify-content-center align-items-center d-lg-none p-3"> */}
                  <p className="mb-1">Powered by</p>
                  <img src={grublogo} className="img-fluid w-25" />
                </div>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </div>
      </section>
      {/* <div className="order-online-btn w-100 p-2 bg-white fixed-bottom d-block d-lg-none"> */}
      <div className="order-online-btn w-100 p-2 bg-white fixed-bottom d-block d-xl-none">
        <a
          href="/order-online"
          className="text-white btn phn-online-btn btn-lg rest-share-link w-100 d-block m-0"
        >
          Order Online
        </a>
      </div>

      {/* ***** Fixed Halal logo ***** */}
      <div className="halal-img-box">
        <img
          src={HalalIcon}
          className="halal-logo-size mx-0 mr-4"
          alt={clientData[0]?.fld_restaurantname}
        />
      </div>
    </>
  );
}

export default CommonHomeNavbar;
