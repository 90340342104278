import { useContext, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import ComplementryIcon from "../../assets/img/Com.png";
import PostApiCall from "../../helper/PostAPI";
import { store } from "../../pages/context/store";
import "./OrderHistoryDrawer.css";
import NonVegetarian from "../../assets/img/nonveg_icon.png";
import Vegan from "../../assets/img/vegan_icon.png";
import Vegetarian from "../../assets/img/veg_icon.png";

const OrderHistoryDrawer = (props) => {
  const handleClose = () => props.offcanvasclose(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [orderDateTime, setOrderDateTime] = useState("");
  const [orderType, setOrderType] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [address, setAddress] = useState("");
  const { currencyCode, setCurrencyCode } = useContext(store);
  const [orderDetails, setOrderDetails] = useState([]);
  const [landmark, setLandMark] = useState("");

  useEffect(() => {
    if (props.orderId != null) {
      PostApiCall.postRequest(
        {
          cartid: props.orderId,
        },
        "getOrderById"
      ).then((result) =>
        result?.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            PostApiCall.postRequest(
              {
                id: null,
                orderid: props.orderId,
                stateid: null,
              },
              "getOrderDetails"
            ).then((results1) =>
              results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                  setOrderDetails(obj1.data);
                } else {
                }
              })
            );
            setOrderNumber(obj.data[0].fld_order_number);
            setOrderDateTime(
              obj.data[0].fld_cart_date + "" + obj.data[0].fld_cart_time
            );
            setOrderType(obj.data[0].fld_order_type);
            setCustomerName(obj.data[0].Customer_name);
            setCustomerMobile(obj.data[0].fld_customer_mobile);
            setPaymentDetails(obj.data[0].fld_payment_mode);
            setAddress(
              obj.data[0].fld_address_1 + " " + obj.data[0].fld_address_2
            );
            setLandMark(obj.data[0].fld_landmark);
          }
        })
      );
    }
  }, [props.offcanvasshow]);
  return (
    <>
      <Offcanvas
        show={props.offcanvasshow}
        onHide={handleClose}
        placement="end"
        className="offcanvas"
      >
        <Offcanvas.Header
          closeButton
          className="text-white border-0 theme-color-masala order-detail-offcanvas"
          closeVariant="white"
        >
          <Offcanvas.Title>
            <h5 className="fw-bold text-white">Order Details</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="mb-5">
          <div className="kot-offcanvas-box">
            <h6 className="fw-bold">Order Number</h6>
            <p>{orderNumber}</p>
          </div>
          <div className="kot-offcanvas-box">
            <h6 className="fw-bold">Order Date & Time</h6>
            <p>{orderDateTime}</p>
          </div>
          <div className="kot-offcanvas-box">
            <h6 className="fw-bold">Order Type</h6>
            <p>{orderType}</p>
          </div>
          <div className="kot-offcanvas-box">
            <h6 className="fw-bold">Customer Details</h6>
            <p>{customerName}</p>
            <p>Mobile # {customerMobile}</p>
          </div>
          {orderType == "TakeAway" ?
            "" :
            <>
              <div className="kot-offcanvas-box">
                <h6 className="fw-bold">Payment Details</h6>
                <p>{paymentDetails}</p>
              </div>
              <div className="kot-offcanvas-box">
                <h6 className="fw-bold">Deliver to</h6>
                <p>{address}</p>
                <h6 className="fw-bold">Landmark</h6>
                <p>{landmark}</p>
              </div>
            </>}
          <div className="kot-offcanvas-box">
            <h6 className="border-bottom py-2 border-top fw-bold">
              Order Summary
            </h6>
            <div className="kot-card-mid-main kot-offcanvas-card-main mx-1">
              {orderDetails?.map((order) => {
                return (
                  <div className="kot-card-mid pb-2 ">
                    <p
                      className={`fw-bold d-flex align-items-center ${order.FLD_Food_Type == "NonVegetarian"
                        ? "non-veg-icon"
                        : "veg-icon"
                        } mb-1`}
                    >
                      {" "}
                      <img
                        className="recipe-type"
                        src={order.FLD_Food_Type == "Vegetarian"
                          ? Vegetarian : order.FLD_Food_Type == "NonVegetarian"
                            ? NonVegetarian
                            : Vegan} />{" "}
                      {order.fld_variantname}
                    </p>
                    {order.fld_complementry &&
                      <div className="complementry-box complementry-box-cart">
                        <span>
                          Complimentary{" "}
                          {/* <img
                            src={ComplementryIcon}
                            className="img-fluid"
                          /> */}
                        </span>
                        <p>{order.fld_complementry}</p>
                      </div>
                    }
                    <div className="d-flex justify-content-between mt-2">
                      <div>
                        <p className="text-dark mb-0">
                          {order.fld_quantity} X {currencyCode}
                          {order.fld_website_discount_price}
                        </p>
                        {order.ConfigDetails != "#" ? (
                          <>
                            {order.ConfigDetails.split(
                              "#"
                            ).filter((dat) => {
                              if (dat.split(
                                ","
                              )[7] != 'properties') {
                                return dat
                              }
                            }).map((config) => {
                              return (
                                <div className="d-flex justify-content-between align-items-center gap-2">
                                  <div className="d-flex gap-2">
                                    <h6 className="mb-0">
                                      <img
                                        src={config != null ? config.split(",")[3] ==
                                          "Vegetarian"
                                          ? Vegetarian
                                          : config.split(
                                            ","
                                          )[3] ==
                                            "NonVegetarian"
                                            ? NonVegetarian
                                            : Vegan
                                          : ""
                                        }
                                        className="recipe-type"
                                      />{" "}
                                      {config != null
                                        ? config.split(
                                          ","
                                        )[1]
                                        : ""}{" "} ({config != null
                                          ? config.split(
                                            ","
                                          )[7]
                                          : ""}){" "}
                                      X{" "}
                                      {config != null
                                        ? config.split(
                                          ","
                                        )[5]
                                        : ""}
                                    </h6>
                                  </div>
                                  <div className="text-end">
                                    <p className="mb-0 small fw-bold d-flex justify-content-start align-items-center">
                                      {currencyCode}{" "}
                                      {config != null
                                        ? Number(
                                          config.split(
                                            ","
                                          )[2]
                                        ).toFixed(2)
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : null}
                      </div>
                      <p className="text-dark">
                        {currencyCode}
                        {order.netvalue}
                      </p>
                    </div>
                  </div>
                );
              })}
              {orderDetails.length != 0
                ? orderDetails[0].fld_special_instruction != "" ?
                  <>
                    <h6 className="border-bottom py-2 border-top">
                      Additional Instructions
                    </h6>
                    <div>
                      <p className="text-dark py-2">
                        {orderDetails.length != 0
                          ? orderDetails[0].fld_special_instruction
                          : ""}
                      </p>
                    </div>
                  </> : "" : ""}
              <h6 className="border-bottom py-2 border-top">Billing Summary</h6>

              <div className="kot-card-mid d-flex justify-content-between mt-2">
                <p className="text-dark fw-bold"> Item Total</p>
                <p className="text-dark">
                  {currencyCode}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_total_net_amount.toFixed(2)
                    : 0.0}
                </p>
              </div>
              {/* <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold"> Coupon - {couponName}</p>
                <p className="text-dark">
                  {currencyCode}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_coupon_discount.toFixed(2)
                    : 0.0}
                </p>
              </div> */}
              {/* <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold"> Offer Discount</p>
                <p className="text-dark">
                  {currencyCode}
                  800
                </p>
              </div> */}
              <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold">Taxes </p>
                <p className="text-dark">
                  {currencyCode}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_total_gst.toFixed(2)
                    : 0.0}
                </p>
              </div>
              <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold">Delivery Charges</p>
                <p className="text-dark">
                  {currencyCode}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_shipping_charge.toFixed(2)
                    : 0.0}
                </p>
              </div>
              {/* <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold">Packing Charges </p>
                <p className="text-dark">
                  {currencyCode}
                </p>
              </div> */}
              <div className="kot-card-mid d-flex justify-content-between">
                <p className="text-dark fw-bold"> Tips</p>
                <p className="text-dark">
                  {currencyCode}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_tip.toFixed(2)
                    : 0.0}
                </p>
              </div>
              <div className="kot-card-mid d-flex justify-content-between border-top border-bottom mt-3 py-3">
                <h5 className="text-dark fw-bold">Grand Total</h5>
                <h5 className="text-dark">
                  {currencyCode}
                  {orderDetails.length != 0
                    ? orderDetails[0].fld_cart_amount_no_shipping.toFixed(2)
                    : 0.0}
                </h5>
              </div>

            </div>
          </div>
        </Offcanvas.Body>
        <div className="mt-0 pt-0 kot-order-btn-main">
          <div className="kot-save ">
            <h6 className="text-white fw-bold">
              Total Order Savings {currencyCode}
              {orderDetails.length != 0 &&
                orderDetails[0].you_save_amount != null
                ? orderDetails[0].you_save_amount.toFixed(2)
                : 0.0}
            </h6>
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default OrderHistoryDrawer;
