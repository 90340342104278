import { useEffect, useState } from "react";
import "../ImageGalleryPage/ImageGalleryPage.css";
import PostApiCall from "../../../helper/PostAPI";
import CommonHomeNavbar from "../Navbar/CommonHomeNavbar";
import Footer from "../Footer/Footer";
import { Image } from "antd";
import { Helmet } from "react-helmet";

const ImageGalleryPage = () => {
  const [gallaryImages, setGallaryImages] = useState([]);
  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_restaurant_id = '225736'`,
        columns: "*",
      },
      "getRestaurantImages"
    ).then((results) =>
      results?.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setGallaryImages(obj.message);
          // Notiflix.Loading.Remove();
        }
      })
    );
  }, []);
  return (
    <>
      <CommonHomeNavbar />

      <Helmet>
        {/* meta tags */}
        <title>
          Gallery | Masala of India | Indian Restaurant in Seattle
        </title>
        <meta
          name="description"
          content=" Immerse yourself in the diverse culture of India through the culinary delights showcased at Masala Of India Gallery. Experience a taste of tradition."
        />
        <meta
          name="keywords"
          content="Gallery , Masala Of India, best indian restaurant in  seattle, indian cuisine in seattle, indian food lunch buffet in seattle, indian restaurants near me delivery, online indian food delivery near me, indian restaurants in seattle washington, best indian restaurants in seattle, indian buffet bellevue,indian food buffets, best indian restaurants in bellevu, indian restaurants in seattle"
        />

        {/* OG tags */}
        <meta
          property="og:title"
          content="Gallery | Masala of India | Indian Restaurant in Seattle"
        />
        <meta
          property="og:description"
          content=" Immerse yourself in the diverse culture of India through the culinary delights showcased at Masala Of India Gallery. Experience a taste of tradition."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content=" https://www.masalaofindia.com/gallery"
        />
        <meta
          property="og:image"
          content=" https://www.masalaofindia.com/static/media/masala-of-india.c5a7d2004e43f4db1721.png"
        />
        <meta property="og:site_name" content="masala of india" />

        {/* Twitter tags */}
        <meta
          name="twitter:title"
          content="Gallery | Masala of India | Indian Restaurant in Seattle"
        />
        <meta
          name="twitter:description"
          content=" Immerse yourself in the diverse culture of India through the culinary delights showcased at Masala Of India Gallery. Experience a taste of tradition."
        />
        <meta
          name="twitter:image"
          content=" https://www.masalaofindia.com/static/media/masala-of-india.c5a7d2004e43f4db1721.png"
        />
        <meta name="twitter:site" content="masala of india" />

        <link rel="canonical" href="https://www.masalaofindia.com/gallery"></link>
      </Helmet>

      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-header-box">
                <h1 class="text-anime">Gallery</h1>
                <nav>
                  <ol class="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Gallery</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Gallary-images container my-4 my-lg-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="content">
              <div className="section-header">
                <h2>Explore the rich flavors of indian cuisine</h2>
                {/* <h3>Palate Pleasures</h3> */}
              </div>
            </div>
          </div>
          <Image.PreviewGroup>
            {gallaryImages?.map((data, index) => {
              return (
                <div className="col-4 mb-4">
                  <Image
                    id={index}
                    src={data.fld_image_url}
                    width="100%"
                  ></Image>
                </div>
              );
            })}
          </Image.PreviewGroup>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ImageGalleryPage;
