import {
  Box,
  FormControl,
  InputLabel, MenuItem,
  Select, TextField
} from "@mui/material";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import {
  Button, Form,
  Offcanvas
} from "react-bootstrap";
import GetApiCall from "../../helper/GetApi";
import PostApiCall from "../../helper/PostAPI";
import useGeoLocation from "../../pages/CustomHooks/useGeoLocation";

function AddressOffcanvas(props) {
  const handleClose = () => props.setShow(false);
  const [currAddressFor, setCurrAddressFor] = useState("myself");
  const [currAddressType, setCurrAddressType] = useState("home");
  const [saveAddressAs, setSaveAddressAs] = useState("");
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [landmark, setLandMark] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    PostApiCall.postRequest(
      {
        columns: "*",
        whereClause: ` where fld_address_id = ${props.addressId}`,
      },
      "getCustomerAddress"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          //   setCurrAddress(obj.message);
          if (props.addressId != null) {
            setName(obj.message[0].fld_name);
            setMobileNumber(obj.message[0].fld_contact_number);
            setAddress1(obj.message[0].fld_address_1);
            setAddress2(obj.message[0].fld_address_2);
            setAddress3(obj.message[0].fld_address_3);
            setPincode(obj.message[0].fld_pincode);
            setLandMark(obj.message[0].fld_landmark);
            setState(obj.message[0].fld_state);
            setCity(obj.message[0].fld_city);
            if (obj.message[0].fld_country != null) {
              PostApiCall.postRequest(
                {
                  whereClause: ` where Country_Id = ${obj.message[0].fld_country}`,
                },
                "getCountryById"
              ).then((results) =>
                results?.json().then((obj2) => {
                  if (results.status == 200 || results.status == 201) {
                    if (obj2.message.length > 0) {
                      setCountry(obj2.message[0]?.Country_Id);
                      getStates(obj2.message[0]?.Country_Id);
                      getCities(obj.message[0]?.fld_state);
                    }
                  }
                })
              );
            }
          } else {
            setName("");
            setMobileNumber("");
            setAddress1("");
            setAddress2("");
            setAddress3("");
            setCountry("");
            setState("");
            setCity("");
            setPincode("");
            setLandMark("");
          }
        }
      })
    );
    GetApiCall.getRequest("getCountry").then((results) =>
      results?.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setCountries(obj.message);
        }
      })
    );
  }, [props.show]);

  function getStates(country) {
    PostApiCall.postRequest({ countryid: country }, "getState").then(
      (resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            setStates(obj.message);
          }
        })
    );
  }

  function getCities(state) {
    PostApiCall.postRequest({ stateid: state }, "getCity").then(
      (resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            setCities(obj.message);
          }
        })
    );
  }
  const location = useGeoLocation();
  function onSaveAddress() {
    PostApiCall.postRequest(
      {
        CUSTOMERID: JSON.parse(localStorage.getItem("LoginDetailsWeb"))[0].CustomerId,
        addressid: props.addressId,
        title: currAddressType,
        name: name,
        mobileNo: mobileNumber,
        flatno: address1,
        area: address2,
        landmark: landmark,
        country: country,
        state: state,
        city: city,
        pincode: pincode,
        action: props.addressId == null ? "INSERT" : "UPDATE",
        latitude: location.coordinates.lat,
        longitude: location.coordinates.lng,
        gstNumber: null,
      },
      "updatecustomeraddress"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Notify.Success(`Address ${props.addressId == null ? "Added" : "updated"} Sucessfully`
          );
          handleClose();
        }
      })
    );
  }
  return (
    <Offcanvas
      show={props.show}
      onHide={() => {
        handleClose();
        setName("");
        setMobileNumber("");
        setAddress1("");
        setAddress2("");
        setAddress3("");
        setCountry("");
        setState("");
        setCity("");
        setPincode("");
        setLandMark("");
      }}
      placement="end"
      className="kot-offcanvas add-address-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h5 className="fw-bold text-white">Enter New Address Details</h5>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="pb-3">
        <div className="kot-offcanvas-box">
          <h6>Add an Address for</h6>
          <Form.Check
            inline
            checked={currAddressFor == "myself" ? true : false}
            onClick={() => setCurrAddressFor("myself")}
            name="group1"
            label="Myself"
            type={"radio"}
          />
          <Form.Check
            inline
            label="Someone Else"
            checked={currAddressFor == "someone else" ? true : false}
            onClick={() => setCurrAddressFor("someone else")}
            name="group1"
            type={"radio"}
          />
        </div>
        <div className="kot-offcanvas-box">
          <h6>Save Address as*</h6>
          <div className="mt-2">
            <Button
              variant="white"
              className={`kot-btn ${currAddressType == "home" ? "" : "bg-white text-dark border"
                } me-2`}
              onClick={() => {
                setCurrAddressType("home");
              }}
            >
              Home
            </Button>
            <Button
              variant="white"
              className={`kot-btn ${currAddressType == "work" ? "" : "bg-white text-dark border"
                } me-2`}
              onClick={() => {
                setCurrAddressType("work");
              }}
            >
              Work
            </Button>
            <Button
              variant="white"
              className={`kot-btn ${currAddressType == "hotel" ? "" : "bg-white text-dark border"
                } me-2`}
              onClick={() => {
                setCurrAddressType("hotel");
              }}
            >
              Hotel
            </Button>
            <Button
              variant="white"
              className={`kot-btn ${currAddressType == "other" ? "" : "bg-white text-dark border"
                } me-2`}
              onClick={() => {
                setCurrAddressType("other");
              }}
            >
              Other
            </Button>
          </div>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            className="mt-3 w-100"
          />
          <TextField
            id="outlined-basic"
            label="Mobile Number"
            variant="outlined"
            value={mobileNumber}
            onChange={(e) => {
              setMobileNumber(e.target.value);
            }}
            className="mt-3 w-100"
          />
          <TextField
            id="outlined-basic"
            label="Address (Line 1)"
            variant="outlined"
            value={address1}
            onChange={(e) => {
              setAddress1(e.target.value);
            }}
            className="mt-3 w-100"
          />
          <TextField
            id="outlined-basic"
            label="Address (Line 2)"
            variant="outlined"
            value={address2}
            onChange={(e) => {
              setAddress2(e.target.value);
            }}
            className="mt-3 w-100"
          />
          <TextField
            id="outlined-basic"
            label="Address (Line 3)"
            value={address3}
            onChange={(e) => {
              setAddress3(e.target.value);
            }}
            className="mt-3 w-100"
          />
          <Box className="mt-3 w-100">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Country"
                variant="outlined"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  getStates(e.target.value);
                }}
              >
                {countries.length > 0 ? (
                  countries.map((country, i) => {
                    return (
                      <MenuItem value={country.value}>{country.label}</MenuItem>
                    );
                  })
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </Box>

          <Box className="mt-3 w-100">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">State</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="State"
                variant="outlined"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                  getCities(e.target.value);
                }}
              >
                {states.length > 0 ? (
                  states.map((state, i) => {
                    return (
                      <MenuItem value={state.value}>{state.label}</MenuItem>
                    );
                  })
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </Box>

          <Box className="mt-3 w-100">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="City"
                variant="outlined"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              >
                {cities.length > 0 ? (
                  cities.map((city, i) => {
                    return <MenuItem value={city.value}>{city.label}</MenuItem>;
                  })
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </Box>
          <TextField
            id="outlined-basic"
            label="Pincode"
            variant="outlined"
            value={pincode}
            onChange={(e) => {
              setPincode(e.target.value);
            }}
            className="mt-3 w-100"
          />

          <TextField
            id="outlined-basic"
            label="Landmark"
            variant="outlined"
            value={landmark}
            onChange={(e) => {
              setLandMark(e.target.value);
            }}
            className="mt-3 w-100"
          />
        </div>
        <div className="mt-3">
          <Button
            variant="white"
            className="kot-btn"
            onClick={() => {
              onSaveAddress();
            }}
          >
            Save this Address
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default AddressOffcanvas;
