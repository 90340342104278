// var urlString = "http://localhost:8033/api-grub/";
var urlString = "https://api.grubdigest.com/api-grub/";

const PostApiCall = {
  postRequest(userData, url) {
    return fetch(urlString + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        schema: 'masala_of_india',
        'x-auth-token': localStorage.getItem('access')
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.setItem('SessionExpired', 'true');
          localStorage.removeItem('OrderData');
          localStorage.removeItem('LoginDetailsWeb');
          window.location.reload();
          return response;
        } else {
          return response;
        }

      })
      .catch((error) => {
        console.log("request failed", error);
        return error;
      });
  },
};

export default PostApiCall;
