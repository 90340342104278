import Footer from "../Footer/Footer";
import CommonHomeNavbar from "../Navbar/CommonHomeNavbar";
import aboutimg from "../../../pages/Masala-of-india/assets/images/about/Image.png";
import "./ChefPage.css";
export default function ChefPage() {
  return (
    <>
      <CommonHomeNavbar />
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-header-box">
                <h1 class="text-anime">Reservation</h1>
                <nav>
                  <ol class="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Reservation</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="book-table-section book-reservation-section my-4 my-lg-5">
        <div className="container-md container-fluid px-0 px-3">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="content">
                <div className="section-header mb-1">
                  {/* <h2>Serve happiness to your guests</h2> */}
                  <h2>Authentic Indian Cuisines</h2>
                  <h3>Unforgettable Dining Experience</h3>
                  <p>
                    Indulge in the vibrant flavors and aromas of Indian cuisine
                    at Masala Of India Cuisine. Our carefully curated menu
                    offers a delectable array of traditional and contemporary
                    dishes, each prepared with fresh ingredients and authentic
                    spices.
                  </p>
                  <p>
                    Reserve your table today and embark on a culinary journey
                    that will transport you to the heart of India.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
                <div className="img">
                <img src={aboutimg} alt="masla of india" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-header">
                  <h2 className="wow fadeInUp">Our Story</h2>
                  <h3 className="">
                    Take a culinary tour of India's royal flavours
                  </h3>
                  <p className="masala-common-content">
                    Using freshly chosen secret ingredients passed down through
                    generations,
                    <br /> Mr. Sanjay Sharma, the owner of Masala Of India has
                    set a new taste standard for Indian food. His fine skills to
                    cater food lovers with the most palatable healthiest Indian
                    food and his respect for more than 100-year-old Indian
                    culinary tradition led him to become one of the featured
                    food vendors at the recent Northgate Festival. The passion
                    to provide tempting food along with the concern for the
                    well-being of the consumers gave birth to the healthiest
                    Indian food cuisine one can find in the whole country.
                  </p>
                  <p className="masala-common-content">
                    Since 2000, Masala of India has been catering Indian
                    indulgences to Indian food lovers giving your taste buds a
                    treat like never before. The sizzling spicy flavours of our
                    appetizers directly from the spice capital of the world will
                    leave you licking your fingers and lips.
                  </p>
                </div>
              </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
