import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";

import NonVegetarian from "../../assets/img/nonveg_icon.png";
import Vegan from "../../assets/img/vegan_icon.png";
import Vegetarian from "../../assets/img/veg_icon.png";
import PostApiCall from "../../helper/PostAPI";



import { store } from "../../pages/context/store";





const CopyConfig = (props) => {
  const { itemId, setItemId } = useContext(store);
  const { selectedCartLineId, setSelectedCartLineId, setShowDetails, showDetails } = useContext(store);

  const { showConfigSlider, setShowConfigSlider } = useContext(store);
  const { quantityAdd, setQuantityAdd } = useContext(store);
  const { showCopyConfig, setShowCopyConfig } = useContext(store);
  //add property
  const { property, setProperty } = useContext(store);
  const { propertyList, setPropertyList } = useContext(store);
  const { setSelectedAddons, setSelectedProperties } = useContext(store);

  const { addOnGroup, setAddOnGroup } = useContext(store);
  const { addOnList, setAddOnList } = useContext(store);

  const { cartData, setCartData } = useContext(store);
  // discount
  const [discountAmount, setdiscountAmount] = useState(null);
  // total
  const [totalAmount, settotalAmount] = useState(0.00);
  const { cartTotal, setCartTotal } = useContext(store);
  const { cartItems, setCartItems } = useContext(store);
  const { currentItemConfig, setCurrentItemConfig } = useContext(store);

  const handleCloseDetails = () => setShowDetails(false);


  return (
    <>
      <Container fluid>
        <Modal
          show={props.show}
          cancel={props.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Choose Config
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Do you want to repeat last config? </h6>
            {currentItemConfig?.split("#").map((data) => {
              return (
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <div className="d-flex gap-2">
                    <h6 className="mb-0">
                      <img
                        src={
                          data != null
                            ? data.split(",")[3] == "Vegetarian"
                              ? Vegetarian
                              : data.split(",")[3] == "NonVegetarian"
                                ? NonVegetarian
                                : Vegan
                            : ""
                        }
                        className="recipe-type"
                      />{" "}
                      {data != null
                        ? data.split(",")[1]
                        : ""}
                    </h6>
                  </div>
                  <div>
                    {data != null
                      ? Number(data.split(",")[2]).toFixed(2)
                      : ""}
                  </div>
                </div>
              )
            })}

            <div className="d-flex gap-2 mt-lg-3">
              <Button
                variant="danger"
                onClick={() => {
                  var login = localStorage.getItem("LoginDetailsWeb");
                  var logindetails = login ? JSON.parse(login)[0] : null;
                  var Order = localStorage.getItem("OrderData");
                  var Orderdetails = JSON.parse(Order);
                  PostApiCall.postRequest(
                    {
                      orderdate: moment().format("lll"),
                      itemdetails: `[{"Item":${itemId},"Quantity":${1},"SpiceLevel":${null},"specialInstruction":"","addons":${null},"property":${null},"copyconfig":"Yes","cartlineid" : ${currentItemConfig?.split("#")[0].split(",")[6]}}]`,
                      status: "INCART",
                      customerid:
                        localStorage.getItem("LoginDetailsWeb") == null
                          ? null
                          : logindetails.CustomerId,
                      createdon: moment().format("lll"),
                      updatedon: moment().format("lll"),
                      orderid:
                        localStorage.getItem("OrderData") == null
                          ? 0
                          : Orderdetails.order_id,
                      updatedby:
                        localStorage.getItem("LoginDetailsWeb") == null
                          ? null
                          : logindetails.CustomerId,

                      stateid: null,
                    },
                    "addToCart"
                  ).then((result) =>
                    result?.json().then((obj) => {
                      if (result.status == 200 || result.status == 201) {
                        //setupdateCart(!updateCart);
                        if (localStorage.getItem("OrderData") == null) {
                          setSelectedAddons([]);
                          setSelectedProperties([])
                          localStorage.setItem(
                            "OrderData",
                            JSON.stringify(obj.message[0])
                          );
                        }
                      } else {
                        Notiflix.Notify.Failure(obj.message);
                      }
                      props.getCart();
                      props.getMenuList()
                      setShowCopyConfig(false)
                    })
                  );
                }}
              >
                Yes
              </Button>
              <Button
                variant="danger"
                className=""
                onClick={() => {
                  setShowCopyConfig(!setShowConfigSlider)
                  //setShowConfigSlider(true);
                  setShowDetails(!showDetails)
                  PostApiCall.postRequest(
                    {
                      WhereClause: ` where FLD_Item_Id = ${itemId}`,
                      columns: ` distinct FLD_Addon_Group,FLD_Max_Addon,FLD_Max_Properties   `,
                    },
                    "getItemAddOnMaster"
                  ).then((result) =>
                    result?.json().then((obj) => {
                      if (result.status == 200 || result.status == 201) {
                        setAddOnGroup(obj.data);
                      }
                    })
                  );
                  PostApiCall.postRequest(
                    {
                      WhereClause: ` where FLD_Item_Id = ${itemId}`,
                      columns: ` distinct FLD_Property_Name,FLD_Max_Addon,FLD_Max_Properties   `,
                    },
                    "GetItemProperty"
                  ).then((result) =>
                    result?.json().then((obj) => {
                      if (result.status == 200 || result.status == 201) {
                        setProperty(obj.data);
                      }
                    })
                  );

                  PostApiCall.postRequest(
                    {
                      WhereClause: ` where FLD_Item_Id = ${itemId}`,
                      columns: ` *  `,
                    },
                    "getItemAddOnMaster"
                  ).then((result) =>
                    result?.json().then((obj) => {
                      if (result.status == 200 || result.status == 201) {
                        setAddOnList(obj.data);
                      }
                    })
                  );
                  PostApiCall.postRequest(
                    {
                      WhereClause: ` where FLD_Item_Id = ${itemId}`,
                      columns: ` *  `,
                    },
                    "GetItemProperty"
                  ).then((result) =>
                    result?.json().then((obj) => {
                      if (result.status == 200 || result.status == 201) {
                        setPropertyList(obj.data);

                      }
                    })
                  );
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={props.close}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}
export default CopyConfig;