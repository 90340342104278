import { createContext, useState } from "react";

export const store = createContext();

const Provider = ({ children }) => {
  const [cart, setCart] = useState(0);
  const [cartItems, setCartItems] = useState(0);
  const [cartTotal, setCartTotal] = useState(0.0);
  const [toggleDrawer, settoggleDrawer] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfigSlider, setShowConfigSlider] = useState(false);
  const [showCopyConfig, setShowCopyConfig] = useState(false);
  const [updateCustomerAddressData, setupdateCustomerAddressData] =
    useState(false);
  const [mapAddress, setMapAddress] = useState(localStorage.getItem("address"));
  const [noDeliveryZone, setNoDeliveryZone] = useState(false);
  const [showFixedBottom, setshowFixedBottom] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [showCouponModal, setshowCouponModal] = useState(false);
  const [signUpModal, setsignUpModal] = useState(false);
  const [updateCart, setupdateCart] = useState(false);
  const [couponName, setCouponName] = useState(null);
  const [currentcat, setcurrentcat] = useState(null);
  const [menu, setMenu] = useState(false);
  const [loginModalItemId, setloginModalItemId] = useState(0);
  const [spiceLevelStore, setspiceLevelStore] = useState(null);
  const [changePasswordVerify, setchangePasswordVerify] = useState(false);
  const [specialInstruction, setSpecialInstruction] = useState(null);
  const [mapPostitionMarkerLat, setmapPostitionMarkerLat] =
    useState("47.70923430939719");
  const [mapPostitionMarkerLang, setmapPostitionMarkerLang] = useState(
    "-122.32561495683619"
  );

  const [activeFooter, setActiveFooter] = useState("");
  const [CatWidth, setCatWidth] = useState("more");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState(null);
  //add on group
  const [addOnGroup, setAddOnGroup] = useState([]);
  const [addOnList, setAddOnList] = useState([]);
  //add property
  const [property, setProperty] = useState(null);
  const [propertyList, setPropertyList] = useState(null);

  const [quantityAdd, setQuantityAdd] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [itemPrice, setItemPrice] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [keyStrings, setKeyStrings] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedCartLineId, setSelectedCartLineId] = useState(null);
  const [currentItemConfig, setCurrentItemConfig] = useState(null);
  const [cartData, setCartData] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [restaurantOpen, setRestaurantOpen] = useState(null);
  const [fssai, setFssai] = useState(null);
  const [restaurantAddress, setRestaurantAddress] = useState(null);
  const [restaurantTime, setRestaurantTime] = useState(null);
  const [restaurantName, setRestaurantName] = useState(null);
  const [loginDisabled, setLoginDisabled] = useState(
    localStorage.getItem("LoginDetailsWeb") != null && localStorage.getItem("SessionExpired") === null ? false : true
  );


  //food details
  const [productDetailBanner, setProductDetailBanner] = useState(null);
  const [productComplementry, setProductComplementry] = useState(null);

  const [bannerDetails, setbannerDetails] = useState([]);
  const [productDetailItem, setProductDetailIem] = useState(null);
  const [productDetailCuisines, setProductDetailCuisines] = useState(null);
  const [productDetailLocation, setProductDetailLocation] = useState(null);
  const [productDetailDescription, setProductDetailDescription] = useState(null);
  const [showDetails, setShowDetails] = useState(false)
  const [showCartItemsDetails, setShowCartItemsDetails] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(localStorage.getItem("SessionExpired") === null ? false : true)

  const [cardAccepted, setcardAccepted] = useState([]);
  const [cuisines, setcuisines] = useState([]);
  const [allcuisines, setallcuisines] = useState([]);

  const [menuListItems, setmenuListItems] = useState([]);

  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [currencyCode, setCurrencyCode] = useState(null);
  const [addToCartDisbabled, setAddToCartDisabled] = useState(false);
  const [customerValidation, setCustomerValidation] = useState("");
  const [clientLogo, setClientLogo] = useState("");
  const [clientDetails, setClientDetails] = useState([]);
  const [itemSpiceLevel, setitemSpiceLevel] = useState("");
  const [activeTab, setActiveTab] = useState(null);
  const [selectedMappedProperty, setSelectedMappedProperty] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [scrollTodiv, setScrollTodiv] = useState(null);
  const [appliedCouponId, setAppliedCouponId] = useState(null);

  // Address Map
  // const [mapPostitionMarkerLat, setmapPostitionMarkerLat] = useState('47.70923430939719');
  // const [mapPostitionMarkerLang, setmapPostitionMarkerLang] = useState('-122.32561495683619')

  return (
    <store.Provider
      value={{
        specialInstruction,
        setSpecialInstruction,
        spiceLevelStore,
        setspiceLevelStore,
        loginModalItemId,
        setloginModalItemId,
        menu,
        setMenu,
        currentcat,
        setcurrentcat,
        cart,
        setCart,
        toggleDrawer,
        settoggleDrawer,
        show,
        setShow,
        updateCustomerAddressData,
        setupdateCustomerAddressData,
        mapAddress,
        setMapAddress,
        noDeliveryZone,
        setNoDeliveryZone,
        showFixedBottom,
        setshowFixedBottom,
        loginModal,
        setLoginModal,
        showCouponModal,
        setshowCouponModal,
        signUpModal,
        setsignUpModal,
        updateCart,
        setupdateCart,
        couponName,
        setCouponName,
        changePasswordVerify,
        setchangePasswordVerify,
        mapPostitionMarkerLat,
        setmapPostitionMarkerLat,
        mapPostitionMarkerLang,
        setmapPostitionMarkerLang,
        activeFooter,
        setActiveFooter,
        CatWidth,
        setCatWidth,
        showAlert,
        setShowAlert,
        message,
        setMessage,
        cartTotal,
        setCartTotal,
        cartItems,
        setCartItems,
        showConfigSlider,
        setShowConfigSlider,
        addOnGroup,
        setAddOnGroup,
        addOnList,
        setAddOnList,
        property,
        setProperty,
        propertyList,
        setPropertyList,
        quantityAdd,
        setQuantityAdd,
        showCopyConfig,
        setShowCopyConfig,
        itemId,
        setItemId,
        customerData,
        setCustomerData,
        keyStrings,
        setKeyStrings,
        itemPrice,
        setItemPrice,
        selectedItemId,
        setSelectedItemId,
        selectedCartLineId,
        setSelectedCartLineId,
        currentItemConfig,
        setCurrentItemConfig,
        cartData,
        setCartData,
        selectedAddons,
        setSelectedAddons,
        selectedProperties,
        setSelectedProperties,
        loginDisabled,
        setLoginDisabled,
        restaurantOpen, setRestaurantOpen, fssai, setFssai, restaurantAddress, setRestaurantAddress, restaurantTime, setRestaurantTime, restaurantName, setRestaurantName,
        productDetailBanner, setProductDetailBanner, productDetailItem, setProductDetailIem, productDetailCuisines, setProductDetailCuisines, productDetailDescription, setProductDetailDescription,
        productDetailLocation, setProductDetailLocation, showDetails, setShowDetails, showLoginModal, setShowLoginModal, cardAccepted, setcardAccepted, cuisines, setcuisines, allcuisines, setallcuisines,
        showCartItemsDetails, setShowCartItemsDetails, itemsToDelete, setItemsToDelete, bannerDetails, setbannerDetails, menuListItems, setmenuListItems, searchText, setSearchText, addToCartDisbabled, setAddToCartDisabled,
        currencyCode, setCurrencyCode,
        customerValidation,
        setCustomerValidation,
        clientLogo,
        setClientLogo,
        itemSpiceLevel,
        setitemSpiceLevel,
        clientDetails,
        setClientDetails,
        activeTab,
        setActiveTab,
        selectedMappedProperty,
        setSelectedMappedProperty,
        showAddressModal, setShowAddressModal,
        productComplementry,
        setProductComplementry,
        scrollTodiv,
        setScrollTodiv,
        appliedCouponId,
        setAppliedCouponId
      }}
    >
      {children}
    </store.Provider>
  );
};
export default Provider;
